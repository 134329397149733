.ugb-video-popup {
  position: relative;
  margin: 0 auto;

  .ugb-video-preview,
  a,
  .ugb-play-button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ugb-video-preview {
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    transition: opacity 0.5s;
    z-index: 1;
    object-fit: fill;
    width: 100%;
  }
  &:hover .ugb-video-preview,
  &.wide .ugb-video-preview {
    opacity: 1;
  }
  a {
    z-index: 3;
    box-shadow: none !important;
    background: transparent !important;
    display: block !important;
    border-bottom: none;
  }
  .ugb-play-button {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$large} {
      opacity: 0;
      transition: 0.5s opacity;
    }
    @media #{$small-only} {
      svg {
        width: 4rem;
      }
    }
  }
  &:hover .ugb-play-button,
  &.wide .ugb-play-button {
    opacity: 1;
  }
  .ugb-play-button--squeezed {
    align-items: flex-start;
    @media #{$large} {
      align-items: flex-end;
      margin-bottom: 2.5rem;
    }
    svg {
      margin-top: 3%;
      width: 4rem;
    }
    @media only screen and (max-width: 350px) {
      svg {
        margin-top: 0%;
        width: 3rem;
        height: 5.5rem;
      }
    }
  }
  svg {
    fill: #fff !important;
  }

  .ugb-video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }

  &.wide {
    @media #{$medium-up} {
      overflow: hidden;
      height: 0;
      padding-top: 56.25%;
      .ugb-video-wrapper {
        width: 100%;
        padding-bottom: 56.25%;
        position: absolute;
        top: 0;
      }
    }
    .ugb-play-button--squeezed {
      align-items: center;
      margin-bottom: 0;
      svg {
        width: auto;
      }
    }
  }
}

.ugb-video__changing-rules {
  position: absolute;
  width: 100%;
  top: 13%;
  svg {
    max-width: 22%;
  }
}
.ugb-video-popup__text-overlay-wrap {
  padding: 1.5rem;
  height: 100%;
  @media #{$large} {
    opacity: 0;
    transition: 0.5s opacity;
    .ugb-video-popup:hover & {
      opacity: 1;
    }
  }
  @media #{$medium-down} {
    display: flex;
    align-items: flex-end;
  }
  .wide & {
    display: flex;
    align-items: flex-end;
  }
  @media #{$small-only} {
    padding: 0.75rem;
  }
}

.ugb-video-popup__text-overlay {
  background-color: $dullyellow;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  @media #{$medium-down} {
    height: auto;
  }
  h3 {
    font-size: 2.4rem;
    @media #{$medium-down} {
      font-size: 1.9rem;
    }
    @media #{$small-only} {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1.15rem;
    @media #{$small-only} {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    @media only screen and (max-width: 350px) {
      margin-bottom: 0.25rem;
      line-height: 1.05;
    }
  }
  .wide & {
    background-color: #ffd01e;
    height: auto;
  }
}
.bp-x:hover {
  background: none !important;
}

;@import "sass-embedded-legacy-load-done:46";