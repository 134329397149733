// Die Styles für ein Tile sind separat, da geplant ist, diese
// als iFrame Widget zur Verfügung stellen zu können.

.icon-phase {
  width: rem-calc(60);
  min-width: rem-calc(60);
  height: rem-calc(60);
  background: url(static/images/ring-phase-one.png) center center no-repeat;
  background-size: rem-calc(42) rem-calc(42);
}

.action-color {
  &.phase_1 {
    .tile-cta,
    .tile-action-holder {
      background-color: $phase_1-color;
    }
    .tile-action-button {
      background: $phase_1-color-dark;
      &:before {
        font-family: "FontAwesome";
        content: "\f004";
        margin-right: 1rem;
      }
    }
    .icon-phase {
      background-image: url(static/images/ring-phase-one.png);
    }
  }

  &.phase_2 {
    .tile-cta,
    .tile-action-holder {
      background-color: $phase_2-color;
    }
    .tile-action-button {
      background: $phase_2-color-dark;
    }
    .icon-phase {
      background-image: url(static/images/ring-phase-two.png);
    }
  }

  &.phase_3 {
    .tile-cta,
    .tile-action-holder {
      background-color: $phase_3-color;
    }
    .tile-action-button {
      background: $phase_3-color-dark;
    }
    .icon-phase {
      background-image: url(static/images/ring-phase-three.png);
    }
  }

  &.phase_4 {
    .tile-cta,
    .tile-action-holder {
      background-color: $phase_4-color;
    }
    .tile-action-button {
      background: $phase_4-color-dark;
    }
    .icon-phase {
      background-image: url(static/images/ring-phase-four.png);
    }
  }
}

.action-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.action-tile-image {
  background: $white center top no-repeat;
  background-size: cover;
  width: 100%;
  height: rem-calc(240);
  position: relative;
}

.action-tile-text {
  padding: 1rem;
  flex-grow: 10;
  background: $white;
}

.tile-support,
.tile-partner {
  color: white;
}

.tile-support {
  z-index: 10;
  position: relative;
  text-shadow: $grey-dark 0 1px 2px;
  padding: rem-calc(15);
}

.tile-partner {
  padding: rem-calc(2) rem-calc(30) rem-calc(7);
  position: absolute;
  right: 0;
  top: 0;
  background-color: $grey-medium;
}

.tile-partner-logo {
  max-width: 5rem;
  img {
    width: 100%;
  }
}

.tile-cta {
  display: flex;
  flex: none;
  align-items: stretch;

  .button {
    display: flex;
    align-items: center;
  }
}

.tile-cta-text {
  //font-weight: $font-weight-bold;
  color: $white;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 0;
}

//
// .teaser-front-action-wrap {
//
//   @media #{$medium-up} {
//     display: table-row;
//     .tile-cta,
//     .tile-action-holder {
//       display: table-cell;
//     }
//
//   }
// }

// flexbox tiles home
.teaser-infobox {
  margin-bottom: 1rem;
  background: $white;
}

@media #{$medium-up} {
  .home-teaser-wrap {
    display: flex;
    justify-content: space-between;
  }

  .home-teaser-column {
    flex-direction: column;
    align-items: stretch;
    width: 49%;
    align-content: space-around;
  }
}

// provisorische styles
.teaser-title {
  font-weight: 400 !important;
}

;@import "sass-embedded-legacy-load-done:38";