/*
* Footer
*/

footer {
  a {
    border: none;
    color: $white;
    &:hover {
      color: $primary-color;
    }
  }
  .button {
    background: $primary-color;
    color: $grey-dark;
    &:hover {
      opacity: $button-hover-opacity;
      color: $grey-dark;
    }
  }

  h2 {
    font-size: $h3-font-size;
  }

  nav li {
    line-height: 2;
  }

  @media #{$medium-up} {
    nav > ul {
      column-count: 3;
    }
    nav li {
      line-height: inherit;
    }
  }
}

.footer-top {
  background: $grey-medium;
  padding: $base-line * 2 0 $base-line * 3;
}

.footer-bottom {
  background: $grey-light;
  padding: $base-line 0;
  p {
    @extend %highlight-font-thin;
    color: $black;
    margin-top: 0.5rem;
  }
  .addthis_custom_follow,
  p {
    float: left;
  }
}

.clientservices {
  li {
    //border-bottom: thin solid $grey-medium;
    padding: $base-line * 0.5 0;
  }
}

.social-media-icons {
  margin-top: -0.5rem;
  @media #{$large-up} {
    margin-top: 1rem;
  }
  li a {
    display: inline-block;
    width: $base-line * 2;
    height: $base-line * 2;
    background: transparent;
    text-align: center;
    font-size: 1.5rem !important;
    line-height: $base-line * 2;
    color: $black;
    border-bottom: none;
    &:hover {
      opacity: $button-hover-opacity;
    }
  }
}

.logo-footer {
  display: block;
  width: 11rem;
  height: 8.5rem;
  border: none;
  margin-top: -4rem;

  @media #{$medium-up} {
    margin-top: -4rem;
    text-align: right;
    margin-left: 2rem;
  }
}

.footer-nav {
  display: flex;
  //justify-content: space-between;
  font-family: FFDINStdCondensedMedium;
  font-weight: normal;
  font-style: normal;
  flex-direction: column;
  margin-left: rem-calc(12px);
  margin-bottom: 1rem;
  li {
    margin-right: 30px;
    &:last-child,
    &#credits {
      margin-right: 0;
    }
    @media #{$medium-down} {
      margin-right: 20px;
    }
  }
  a {
    border-bottom: none;
    color: #313130;
    display: block;
    position: relative;
    text-transform: uppercase;
    //z-index: 2000;
    font-size: rem-calc(18);
  }

  .credits-rollover {
    position: relative;
    display: none;
    width: 0;
    @media only screen and (max-width: 904px) {
      //special breakpoint because the footer nav in german is to wide!
      height: 0;
      display: block;
      visibility: hidden;
      position: relative;
      bottom: 0;
      left: 10px;
      padding-top: 0.5rem;
      &.toggle-show {
        height: 70px;
      }
    }
    &:hover {
      display: flex;
      visibility: visible;
    }
    &.show {
      @media #{$medium-up} {
        display: flex;
      }
    }
    &.toggle-show {
      @media #{$medium-down} {
        display: flex;
        visibility: visible;
      }
    }
  }
  .credits-rollover-inner {
    position: absolute;
    left: -3rem;
    top: 1.75rem;
    width: 12rem;
    @media only screen and (max-width: 904px) {
      left: 0;
      top: 0;
    }
    .credits--logo {
      img {
        display: inline;
        width: 4rem;
        margin-left: 0.75rem;
        transform: translateY(-2px);
      }
    }
    p {
      margin: 0;
    }
  }
  @media only screen and (min-width: 904px) {
    //special breakpoint because the footer nav in german is to wide!
    flex-direction: row;
    //margin-left: 0;
    margin-bottom: 0;

    a {
      font-size: rem-calc(19);
    }
  }
}

.footer__subline {
  margin-left: rem-calc(12px);
  @media only screen and (min-width: 904px) {
    //special breakpoint because the footer nav in german is to wide!
    //margin-left: 0;
  }
}

.footer__social {
  display: none;
  justify-content: flex-end;
  align-items: center;
  .meta-nav__newsletter {
    margin-top: 2rem;
    @extend %sans-serif-bold;
  }
  @media #{$large-up} {
    display: flex;
  }
}

.footer__social--small {
  display: flex;
  justify-content: flex-end;
  .social-media-icons li a {
    width: 2rem;
    i {
      font-size: 1.6rem;
    }
  }
  .meta-nav__newsletter {
    margin-top: 0;
    margin-bottom: 0;
    @extend %sans-serif-bold;
    a {
      margin-left: 0;
      @media #{$medium-down} {
        padding: 0.2rem;
      }
    }
  }
  @media #{$large-up} {
    display: none;
  }
}
.footer__social-inner--small {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: rem-calc(12px);
}
// --- sticky footer, http://ryanfait.com/sticky-footer/
// --- only apply to medium up
@media #{$medium-up} {
  html,
  body,
  #outer-wrapper,
  #inner-wrapper {
    height: 100%;
  }
  .stickyfooter-content-wrapper {
    min-height: 100%;
    margin: 0 auto; /* the bottom margin is the negative value of the footer's height */
    //margin-bottom: -$sticky-footer-height;
  }
  footer,
  .stickyfooter-push {
    height: $sticky-footer-height; /* .push must be the same height as .footer */
  }
}

;@import "sass-embedded-legacy-load-done:36";