.pesco-front-blog-container,
.pesco-front-teaser-container {
  padding: $base-line 0 $base-line * 1.5;
}

.pesco-front-blog-container {
  background: $white;
}

.pesco-front-teaser-container {
  background: $grey-light-bg;

  .teaser-action {
    position: relative;
    color: white;
    margin-bottom: 1rem;
  }

  .teaser-infobox {
    background: white;
    color: $body-font-color;
    padding: 2rem;
  }

  .action-bg {
    //background: transparent center center no-repeat;
    background: transparent center 20% no-repeat;
    background-size: cover;
    padding-bottom: 35%;
    min-height: rem-calc(204);
    position: relative;
  }
  .home-teaser-large .action-bg {
    padding-bottom: 27%;
  }

  .teaser-action-caption {
    //background: white;
    color: $body-font-color;
    display: flex;
    justify-content: space-between;
    align-content: stretch;
  }
  .tile-cta-text {
    flex-grow: 1;
  }
  .button {
    margin: 0;
  }

  @media #{$medium-up} {
    .button {
      padding: rem-calc(20) rem-calc(40);
      //font-size: rem-calc(17);
    }
  }
}

.content-type,
.introtext {
  padding-top: $base-line * 1.5;
  padding-bottom: $base-line * 1.5;
}

.alternating:nth-child(odd) {
  background-color: white;
}

.main-content > .richtext:first-child {
  text-align: center;
  p {
    font-size: 1.1rem;
  }
}

.full-page-width {
  margin: 0 auto;
  max-width: $large-breakpoint;
}
.full-width {
  width: 100%;
}

.mediafile-inner {
  padding: 2rem;
  width: 50%;

  @media #{$large-up} {
    width: 40%;
  }
}

.mediafile-caption {
  margin-top: 0.5rem;
}

.mediafile .copyright {
  font-size: $small-font-size;
  margin-top: -$base-line;
  padding-right: 1rem;
  text-shadow: $black-transparent 1px 1px;
}

.content-type + .content-type.download {
  //margin-top: -$base-line * 4;
  margin-top: -$base-line * 3;
  a {
    line-height: 150%;
  }
}

// Masonry Layout
.masonry-list {
  @media #{$medium-up} {
    column-gap: 2rem;
    column-count: 2;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}
.masonry-item {
  column-break-inside: avoid;
  break-inside: avoid-column;
  margin-bottom: $base-line;
}

// Accordion Elements
.accordion-list {
  display: none;
  visibility: hidden;
  li.visible &,
  a.active + & {
    display: block;
    visibility: visible;
  }
}

// Blog Overview Home

.blog-teaser-item {
  /* border-bottom: 1px solid $grey-medium; */
}

.blog-teaser-meta {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.pagination a {
  border-bottom: 0;
}

.mediafile + .blog-text {
  margin-top: $base-line;
}

.label-category {
  @include label(
    $padding: 0.3rem 0.6rem,
    $text-size: rem-calc(13),
    $bg: $grey-medium,
    $radius: $global-rounded
  );
  margin-left: 0.5rem;
  font-weight: $font-weight-thin;
  color: $white;
  margin-bottom: 0;
  border: none !important;
}

.label-tag {
  @include label(
    $padding: 0.3rem 0.6rem,
    $text-size: rem-calc(13),
    $bg: $white,
    $radius: $global-rounded
  );
  margin-left: 0.5rem;
  font-weight: $font-weight-thin;
  color: $black;
  margin-bottom: 0;
  border: 1px solid $black !important;
}

.entry-content {
  padding-bottom: $base-line;
}

// Blog
.blog-category-list {
  margin-bottom: $base-line * 2;

  > li {
    border-bottom: thin solid $grey-light-font;
    padding: 0.5rem 0;
    a {
      border-bottom: none !important;
      display: block;
      &.active {
        font-weight: $font-weight-bold;
      }
    }
    > a.active,
    > a.active:hover {
      cursor: default;
      color: $black;
      .fa-angle-right {
        transform: rotate(90deg);
      }
    }
  }
}

.blog-category-title {
  font-size: $h5-font-size;
  span {
    padding-left: $base-line * 0.25;
  }
}

// Team
.team-list {
  h2 {
    font-size: $h4-font-size;
    line-height: 1.6rem;
    padding: 1rem 1rem 0.5rem;
  }
  p {
    padding: 0rem 1rem 1.2rem;
  }
}
.team-member-portrait {
  margin-top: 0.5rem;
}

// Form
.content-form {
  ul {
    @extend .list-no-margin;
    li {
      display: inline;
      padding-right: $base-line;
      label {
        display: inline;
      }
    }
  }
}

.logolist {
  // remove default link border
  a {
    border-bottom: none;
  }
}

;@import "sass-embedded-legacy-load-done:41";