// table behaviour
.rwd-table {
  th {
    display: none;
  }

  td {
    display: block;
    &:before {
      content: attr(data-th) ": ";
      width: 8rem;
      display: inline-block;
      @media (min-width: $small-breakpoint) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;
    @media (min-width: $small-breakpoint) {
      display: table-cell;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// Hack foundation styles
table tr td,
table tr th {
  font-size: 1rem !important;
  background: transparent;
}
table tr.even,
table tr.alt,
table tr:nth-of-type(even) {
  background: transparent;
}

// custom styles
.rwd-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  @media #{$medium-up} {
    margin-bottom: $base-line;
  }
  th {
    background: $grey-light-bg;
    font-weight: 300;
  }
  tr {
    border-bottom: thin solid $grey-light;
    &:last-child {
      border: none;
    }
  }
  th,
  td {
    padding: 1rem 0.5rem !important;
  }
  td {
    border-bottom: thin solid $grey-light;
    &:last-child {
      margin-bottom: $base-line * 1.5;
      @media (max-width: $small-breakpoint) {
        border-bottom: none;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:39";