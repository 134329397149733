@use "sass:math";

$columns: 12;
$gutter: 24px;

$xs: 0;
$sm: $small-breakpoint;
$md: $medium-breakpoint;
$lg: $large-breakpoint;
$xl: $xlarge-breakpoint;

$media-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
);

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

[class*="block"] {
  min-height: 1px;
  flex: 1;
  padding-left: $gutter * 0.5;
  padding-right: $gutter * 0.5;
}

/* Generate Columns */
@each $key, $breakpoint in $media-breakpoints {
  @media (min-width: $breakpoint) {
    @for $i from 1 through $columns {
      $percent: math.div($i, $columns) * 100%;
      .block-#{$key}-#{$i} {
        flex: 0 0 $percent;
        max-width: $percent;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:45";