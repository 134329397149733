@charset "UTF-8";

@import "scss/general";

.fa {
  font-style: normal !important;
}

/* Additional overrides */

no-feinheit {
  .top-bar-language .top-bar-list-item {
    border-bottom: none !important;
  }

  .header-actions-col {
    opacity: 0 !important;
    pointer-events: none !important;
    visibility: hidden !important;
  }
}
