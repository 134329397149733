/*
* Typography
*/

// $font-weight-thin: 300;
// $font-weight-normal: 400;
// $font-weight-bold: 700;
// $font-weight-black: 800;

// $font-family-sans-serif: 'Nunito', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;  // 400, 700
// $font-family-highlight: 'Oswald', 'Nunito', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;   // 300, 400

// Nunito Regular: 400
// Nunito Bold: 700
// Oswald Light: 300
// Caslon Book: 300
// Caslon Book Italic: 400 italic
// Caslon Black Italic: 800 italic

// colors

@use "sass:math";

$blue: #005f87;
$yellow: #ffce00;
$orange: #e79936;
$green: #8aaf6e;
$petrol: #5c7e9f;
$ming: #346c75;
$red: #e9866e;
$olive: #72795e;

$darkred: #c56269;
$vividred: #e6202a;
$darkcyan: #138aa1;
$lightgreen: #aeb93c;
$pureorange: #f39600;
$dullyellow: #f7da83;

.vividred {
  background-color: $vividred;
}
.darkcyan {
  background-color: $darkcyan;
}
.lightgreen {
  background-color: $lightgreen;
}

.pureorange {
  background-color: $pureorange;
}

.ming {
  background-color: $ming;
}
.darkred {
  background-color: $darkred;
}
.olive {
  background-color: $olive;
}

.petrol {
  background-color: $petrol;
}

.red {
  background-color: $red;
}

.green {
  background-color: $green;
}

.orange {
  background-color: $yellow;
}

.blue {
  background-color: $blue;
}
.color-ming {
  color: $ming;
}
.color-darkred {
  color: $darkred;
}
.color-olive {
  color: $olive;
}

.color-petrol {
  color: $petrol;
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.color-orange {
  color: $yellow;
}

.color-blue {
  color: $blue;
}
.color-vividred {
  color: $vividred;
}

.color-darkcyan {
  color: $darkcyan;
}

.color-lightgreen {
  color: $lightgreen;
}

.color-pureorange {
  color: $pureorange;
}

@font-face {
  font-family: "ITC Caslon No. 224 W01_n3";
  src: url("fonts/93ac235a-65ad-465f-9d63-0b0cfad056f5.eot?#iefix")
    format("eot");
}
@font-face {
  font-family: "ITC Caslon No. 224 W01";
  src: url("fonts/93ac235a-65ad-465f-9d63-0b0cfad056f5.eot?#iefix");
  src:
    url("fonts/93ac235a-65ad-465f-9d63-0b0cfad056f5.eot?#iefix") format("eot"),
    url("fonts/7d1a7ee9-7c7a-4fba-bc8d-10b76db99411.woff2") format("woff2"),
    url("fonts/26c98b73-8919-4b03-bd6e-6d5a01b98db5.woff") format("woff"),
    url("fonts/3d754b3d-d1e0-4810-9a4b-85db59efb423.ttf") format("truetype"),
    url("fonts/23bc91a5-1107-43a9-9f0c-de33253390ae.svg#23bc91a5-1107-43a9-9f0c-de33253390ae")
      format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ITC Caslon No. 224 W01_i4";
  src: url("fonts/9ad398bb-e953-4c74-9525-63927521ddf4.eot?#iefix")
    format("eot");
}
@font-face {
  font-family: "ITC Caslon No. 224 W01";
  src: url("fonts/9ad398bb-e953-4c74-9525-63927521ddf4.eot?#iefix");
  src:
    url("fonts/9ad398bb-e953-4c74-9525-63927521ddf4.eot?#iefix") format("eot"),
    url("fonts/0e802b1f-f870-4f05-8ec8-9d480850162d.woff2") format("woff2"),
    url("fonts/39ae4348-2717-4dc2-9ce1-56ad45cf9101.woff") format("woff"),
    url("fonts/5eeec0a9-b178-4005-a89d-1ee560497d5e.ttf") format("truetype"),
    url("fonts/77cf424b-494e-48df-9c6b-dbc91f1e9a3a.svg#77cf424b-494e-48df-9c6b-dbc91f1e9a3a")
      format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "ITC Caslon No. 224 W01_i8";
  src: url("fonts/177eaaf7-9a75-4860-9c11-851a916e9e9f.eot?#iefix")
    format("eot");
}
@font-face {
  font-family: "ITC Caslon No. 224 W01";
  src: url("fonts/177eaaf7-9a75-4860-9c11-851a916e9e9f.eot?#iefix");
  src:
    url("fonts/177eaaf7-9a75-4860-9c11-851a916e9e9f.eot?#iefix") format("eot"),
    url("fonts/e4875902-5d22-4724-8869-5afed20d4a20.woff2") format("woff2"),
    url("fonts/2f767121-ecba-4cc4-8f35-1054b12b0677.woff") format("woff"),
    url("fonts/1e3c1eed-851f-4407-ac77-51ea6e781fd7.ttf") format("truetype"),
    url("fonts/b0d4436c-2309-4e97-b65b-f9633edcd0d5.svg#b0d4436c-2309-4e97-b65b-f9633edcd0d5")
      format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "FFDINStdMedium";
  src: url("fonts/38E36F_0_0.eot");
  src:
    url("fonts/38E36F_0_0.eot?#iefix") format("embedded-opentype"),
    url("fonts/38E36F_0_0.woff2") format("woff2"),
    url("fonts/38E36F_0_0.woff") format("woff"),
    url("fonts/38E36F_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FFDINStdBlack";
  src: url("fonts/38E36F_1_0.eot");
  src:
    url("fonts/38E36F_1_0.eot?#iefix") format("embedded-opentype"),
    url("fonts/38E36F_1_0.woff2") format("woff2"),
    url("fonts/38E36F_1_0.woff") format("woff"),
    url("fonts/38E36F_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "FFDINStdCondensedMedium";
  src: url("fonts/38E36F_2_0.eot");
  src:
    url("fonts/38E36F_2_0.eot?#iefix") format("embedded-opentype"),
    url("fonts/38E36F_2_0.woff2") format("woff2"),
    url("fonts/38E36F_2_0.woff") format("woff"),
    url("fonts/38E36F_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "FFDINStdCondensedBlack";
  src: url("fonts/38E36F_3_0.eot");
  src:
    url("fonts/38E36F_3_0.eot?#iefix") format("embedded-opentype"),
    url("fonts/38E36F_3_0.woff2") format("woff2"),
    url("fonts/38E36F_3_0.woff") format("woff"),
    url("fonts/38E36F_3_0.ttf") format("truetype");
}
// We use these to define default font stacks
$font-family-sans-serif: "FFDINStdMedium", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif; // 400, 700
$font-family-highlight: "Oswald", "Nunito", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif; // 300
$font-family-serif: "ITC Caslon No. 224 W01", "Georgia", "Times New Roman",
  "Times", serif;

%sans-serif-regular {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-style: normal;
}

%sans-serif-bold {
  font-family: FFDINStdCondensedBlack, "sans-serif";
  font-weight: normal;
  font-style: normal;
}

%serif-black-italic {
  font-family: $font-family-serif;
  font-weight: 800;
  font-style: italic;
  line-height: 1;
}

%serif-book-italic {
  font-family: $font-family-serif;
  font-weight: 400;
  font-style: italic;
}

%serif-book {
  font-family: $font-family-serif;
  font-weight: 300;
  font-style: normal;
}

%highlight-font-thin {
  font-family: FFDINStdCondensedMedium;
  font-weight: normal;
  font-style: normal;
}

%highlight-font-medium {
  font-family: FFDINStdCondensedBlack;
  font-weight: normal;
  font-style: normal;
}

.FFDINStdMedium {
  font-family: FFDINStdMedium;
  font-weight: normal;
  font-style: normal;
}
.FFDINStdBlack {
  font-family: FFDINStdBlack;
  font-weight: normal;
  font-style: normal;
}
.FFDINStdCondensedMedium {
  font-family: FFDINStdCondensedMedium;
  font-weight: normal;
  font-style: normal;
}
.FFDINStdCondensedBlack {
  font-family: FFDINStdCondensedBlack;
  font-weight: normal;
  font-style: normal;
}

.din-h1,
.din-h2,
.din-h3 {
  @extend .FFDINStdCondensedBlack;
}

.din-h4 {
  @extend .FFDINStdBlack;
}

.din-h1 {
  font-size: rem-calc(48);
  @media #{$medium-up} {
    font-size: rem-calc(56);
  }
  @media #{$large-up} {
    font-size: rem-calc(60);
  }
}

.din-h2 {
  letter-spacing: 1px;
  font-size: rem-calc(36);
  line-height: 0.9;

  @media #{$medium-up} {
    font-size: rem-calc(40);
    line-height: 1;
  }
  @media #{$large-up} {
    font-size: rem-calc(40);
    line-height: 1;
  }
}

.din-h3 {
  font-size: rem-calc(26);
  @media #{$medium-up} {
    font-size: rem-calc(30);
  }
  @media #{$large-up} {
    font-size: rem-calc(34);
  }

  &--orange {
    color: $orange;
  }
  &--yellow {
    color: $yellow;
  }

  line-height: 1;
}

.din-h4 {
  font-size: rem-calc(26);
  @media #{$medium-up} {
    font-size: rem-calc(30);
  }
  @media #{$large-up} {
    font-size: rem-calc(34);
  }
}

.din-condensed-large {
  @extend .FFDINStdCondensedMedium;
  font-size: rem-calc(26);
  @media #{$medium-up} {
    font-size: rem-calc(30);
  }
  @media #{$large-up} {
    font-size: rem-calc(34);
  }
}

.din-small {
  @extend .FFDINStdMedium;
  font-size: rem-calc(16);
  letter-spacing: 1px;
  @media #{$medium-up} {
    font-size: rem-calc(18);
    line-height: 1;
  }
}

.din-serif {
  @extend %serif-book-italic;
  font-size: 0.8rem;

  line-height: 1.2;
  @media #{$large-up} {
    font-size: rem-calc(16);
  }
  &.din-serif--hyphens {
    hyphens: auto;
  }
}
.din-serif--large {
  @extend %serif-book-italic;
  font-size: rem-calc(20);
  line-height: 1.5;
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
}
.din-bold {
  @extend .FFDINStdMedium;
  font-size: 0.8rem;
  line-height: 1.5;
  @media #{$large-up} {
    font-size: rem-calc(16);
  }
}
.din-medium {
  @extend .FFDINStdCondensedBlack;
  font-size: rem-calc(16);
  @media #{$medium-up} {
    font-size: rem-calc(18);
    line-height: normal;
    letter-spacing: 1px;
  }
}
.din-medium--small {
  @extend .FFDINStdCondensedBlack;
  font-size: rem-calc(14);
  letter-spacing: 1px;
  @media #{$large-up} {
    font-size: rem-calc(16);
  }
}

.din-large {
  @extend .FFDINStdCondensedBlack;
  font-size: rem-calc(20);
  letter-spacing: 1px;
  @media #{$medium-up} {
    font-size: rem-calc(22);
    line-height: normal;
  }
}

.serif-very-large {
  font-size: rem-calc(60);
  line-height: 1;
  @media #{$large-up} {
    font-size: rem-calc(93);
  }
}

.h3 {
  @extend %serif-black-italic;
}

p {
  line-height: 1.2;
}

body {
  @extend %sans-serif-regular;
  -webkit-font-smoothing: antialiased;
}

h1 {
  @extend %serif-black-italic;
  font-size: rem-calc(42);
  text-align: center;
  @media #{$medium-up} {
    font-size: rem-calc(42);
  }
  @media #{$large-up} {
    font-size: rem-calc(54);
  }
}

h1.h1-large,
.h1-large h1,
.h1-large h2 {
  font-size: rem-calc(60);
  line-height: 1.3em;
  margin-bottom: $base-line * 0.5;
}

.h1-large h2 {
  font-size: $h2-font-size;
}

h1 + p {
  max-width: 960px;
  margin: 0 auto $base-line;
  text-align: center;
  font-size: rem-calc(16);
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  @media #{$large-up} {
    font-size: rem-calc(20);
  }
}
h2.h2-small {
  font-size: rem-calc(22);
  //line-height: $base-line*2.2;
  @media #{$large-up} {
    font-size: $h2-font-size-smaller;
    //line-height: $base-line*2.5;
  }
}

h2.h2-large {
  //font-size: $h1-font-size;
  @media #{$large-up} {
    font-size: $h1-font-size;
  }
}

h2,
h3,
.h3,
h4,
h5 {
  @extend %sans-serif-bold;
  //@extend %highlight-font-thin;

  > a {
    border-bottom: none !important;
    text-decoration: none;
  }
}

h3,
.h3 {
  font-size: rem-calc(22);
  line-height: 1;
}

h4 {
  font-size: rem-calc(18);
}

h5 {
  font-size: rem-calc(16);
  text-transform: uppercase;
}

h1.highlight-font-light,
.highlight-font-light h1,
.highlight-font-light h2 {
  // Produkteseite Moodboard
  @extend %highlight-font-medium;
  text-align: left;
  text-shadow: 1px 2px 4px rgba(#000, 0.5); // text-shadow on image
  //text-shadow: 1px 2px 2px rgba(#000,0.3);  // text-shadow on color
  color: $white;
  & + p {
    text-align: left;
  }
}

h1.highlight-font-dark {
  @extend %highlight-font-medium;
  color: $black;
  margin-top: rem-calc(20);
}

h2.highlight-font-dark {
  // Produkte im Aufbau
  @extend %highlight-font-thin;
  //line-height: $base-line*3;
  color: $black;
}

h2.highlight-font-light {
  // Footer
  @extend %highlight-font-thin;
  color: $white;
}

a {
  color: $black;
  border-bottom: 2px solid $primary-color;

  &:hover {
    color: $primary-color;
  }
}

p {
  line-height: $base-line-height;
  @extend %sans-serif-regular;
}

p.lead {
  line-height: 1.4;
  font-size: rem-calc(16);
  margin-bottom: 0.5rem;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  @media #{$large-up} {
    font-size: rem-calc(20);
  }
}

.cta-claim {
  @extend %highlight-font-thin;
  color: $white;
  //text-shadow: 1px 2px 2px rgba(#000,0.3);
  font-size: rem-calc(30);
  line-height: 1.4em;
  text-align: center;
  @media #{$medium-up} {
    font-size: rem-calc(34);
  }
  @media #{$large-up} {
    font-size: rem-calc(38);
  }
}

// smaller fonts
p,
.tile-cta-text,
.blog-teaser-meta,
.footer-top ul li a,
.timeline-box-inner a,
button,
.button,
.checkout-status-item,
.main-content ul {
  font-size: math.div($base-font-size, 1.1);
  @media #{$medium-up} {
    font-size: $base-font-size;
  }
}

a.no-decoration {
  border: none;
  &:hover,
  &:focus {
    border: none;
    opacity: 1;
    color: inherit;
  }
}

;@import "sass-embedded-legacy-load-done:34";