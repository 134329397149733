:root {
  --accent: #ffce00;
  --we-share-green: #8bb06e;
  --we-share-red: #bc7c69;
  --we-share-blue: #7c98b4;
}

button.button--clean {
  background: none;
  padding: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover,
  &:focus-visible {
    opacity: 0.66;
  }
}

// .read-more {
//   @extend %highlight-font-medium;
// }

.moodboard-text {
  h1 {
    @extend .template__title;
  }
  h2 {
    @extend .template__sub, .template__sub--centered;
  }
}

// nasty overrides
.we-share {
  .richtext h2 {
    text-transform: uppercase;
  }
  .content-type {
    padding-bottom: 1rem;
  }
}

.we-share-teaser {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (min-width: $small-breakpoint) {
      flex-direction: row;
    }
  }
  &.type {
    &--banana {
      &-de .we-share-teaser__image {
        @media (min-width: $small-breakpoint) {
          order: 3;
        }
        background-image: url(./static/images/we_share/banana_de.svg);
      }
      &-en .we-share-teaser__image {
        @media (min-width: $small-breakpoint) {
          order: 3;
        }
        background-image: url(./static/images/we_share/banana_en.svg);
      }
      &-fr .we-share-teaser__image {
        @media (min-width: $small-breakpoint) {
          order: 3;
        }
        background-image: url(./static/images/we_share/banana_fr.svg);
      }
    }
    &--orange {
      .we-share-teaser__image {
        order: 1;
        @media (min-width: $small-breakpoint) {
          background-position: right;
        }
        background-image: url(./static/images/we_share/orange.svg);
      }
    }
  }
  &__info {
    flex: 0 1 50%;
    width: 100%;
    order: 2;
  }
  &__image {
    flex: 0 1 50%;
    order: 1;
    min-height: 24rem;
    background: no-repeat center;
    @media (min-width: $small-breakpoint) {
      background-position: left;
    }
    @media (max-width: $small-breakpoint) {
      width: 100%;
    }
  }

  &__text {
    h3 {
      text-transform: uppercase;
      font-size: 2rem;
    }
    p {
      @extend %highlight-font-thin;
      font-size: 1.5rem;
    }
  }
}

.we-share-teaser,
.we-share-popup {
  &__popups {
    padding: 0;
    margin: 1rem 0;
    list-style: none;
  }
  .title {
    @extend .template__sub;
    font-size: 1.33rem;
    color: var(--accent);

    border-top: 2px solid $black;
    margin-top: 0.5em;
    padding-top: 0.5em;
  }
  &__popup {
    .we-share-button {
      text-align: left;
      width: 100%;
    }
  }
}

.we-share-popup {
  border: none;
  border-radius: 0;

  margin: 1rem auto;
  right: 0;
  max-width: calc(100% - 2rem);
  min-height: auto;
  @media (min-width: $small-breakpoint) {
    max-width: calc(65ch + 5rem);
  }

  &__lead {
    background-color: var(--accent);
    padding: 2rem 2.5rem;
    h3 {
      @extend .template__sub;
      margin-bottom: 1.25rem;
    }
  }
  &__list {
    padding: 2rem 2.5rem 0;
  }
  &__popups {
    padding: 0 2.5rem 2rem;
  }
  &__separator {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -3rem;
      height: 4rem;
      width: 100%;
      background: linear-gradient(
        to bottom right,
        var(--accent) 49.5%,
        $white 50.5%
      );
    }
  }

  .close-reveal-modal {
    background: none;
    color: $black;
    @extend .din-serif--large;
    icon {
      font-size: 2rem;
      font-weight: normal;
      height: 1.5rem;
      line-height: 0.6;
    }
  }
}
.color {
  &--blue {
    --accent: var(--we-share-blue);
  }
  &--green {
    --accent: var(--we-share-green);
  }
  &--red {
    --accent: var(--we-share-red);
  }
}

;@import "sass-embedded-legacy-load-done:48";