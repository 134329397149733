// Layout

.columns--margin {
  margin-bottom: $general-margin;
}

.padding--left {
  padding-left: 1rem;

  @media #{$medium-up} {
    padding-left: 3rem;
  }
}

.margin--top {
  margin-top: $general-margin;
}

.margin--bottom {
  margin-bottom: $general-margin;
}

.margin--bottom--medium {
  @media (min-width: $small-breakpoint) {
    margin-bottom: $general-margin;
  }
}

.column__item {
  margin-bottom: 24px;
  //height: 320px;
  overflow: hidden;

  @media (min-width: $small-breakpoint) {
    min-height: 455px;
    height: 455px;
    .main-content--tube-entry & {
      min-height: auto;
      height: auto;
    }
  }
  //&.column__item--flex {
  //  display: flex;
  //  align-items: center;
  //}
}

.columns--min-height {
  max-height: 550px;
  overflow: hidden;
  @media (min-width: $small-breakpoint) {
    min-height: 400px;
  }
}

.main-content {
  margin-top: $general-margin;
  //display: flex;
  //flex-direction: column;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.flex-align {
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.position--relative {
  position: relative;
}

// Sliders

// Border

.border-row {
  //border-bottom: 35px solid transparent;
  width: 100%;
  padding: 1rem;
  // border-image: 15 repeating-linear-gradient(-45deg, $yellow 0, $yellow 0.0 .4rem, transparent 0, transparent 0.9rem);
  div {
    background-repeat: no-repeat;
    background-size: cover;
    height: 3rem;
    width: 100%;
    @media (min-width: $small-breakpoint) {
      background-size: contain;
    }
  }
}

// TIMELINE

.timeline__title {
  margin-bottom: 1rem;

  @media (min-width: $small-breakpoint) {
    margin-bottom: 3rem;
  }
}

.timeline__header {
  text-align: left;
  font-size: 4rem;
  display: block;
  //transform: translateY(-19px);

  span {
    @extend %sans-serif-bold;
    line-height: normal;
  }
  //margin-bottom: -1.3rem;
}

.timeline__subheader {
  text-align: left;
  font-size: 2rem;
  @media (max-width: $small-breakpoint) {
    background: $yellow;
    padding: 0.1rem;
    width: 16.6rem;
  }
  span {
    @media (min-width: $small-breakpoint) {
      background: $yellow;
      padding: 0.1rem 0.5rem;
    }
    @extend %serif-black-italic;
  }
  margin-bottom: 1rem;
}

.timeline__textbox {
  margin-bottom: 4rem;
  h3 {
    //line-height: 1.2;
    font-size: 1.8rem;
    @extend %serif-black-italic;
  }

  @media (max-width: $small-breakpoint) {
    hyphens: auto;
  }
}

.timeline__textbox--margin {
  margin-bottom: 5rem;
}

.timeline__textblock--padding {
  @media (min-width: $small-breakpoint) {
    padding-right: 10rem;
  }
}

.timeline__topheader {
  @media (min-width: $small-breakpoint) {
    margin-top: 6rem;
  }
  text-align: left;
  font-size: 2rem;
  span {
    @extend %sans-serif-bold;
  }
}

.timeline__imagegroup {
  position: relative;
  min-height: 33rem;
}

.imagegroup__image {
  position: absolute;
  display: block;
}

.imagegroup__image--1 {
  top: 0;
  right: 0;
  height: 70%;
}

.imagegroup__image--2 {
  opacity: 0.9;
  bottom: 3rem;
  left: 0;
  max-height: 18rem;
}

.imagegroup__image--3 {
  top: 0;
  right: 0;
  height: 70%;
}

.imagegroup__image--4 {
  bottom: 5rem;
  right: 10rem;
  max-height: 18rem;
}

.accordion-title {
  width: 100%;
  text-align: right;
  padding-right: 3rem;
  margin-bottom: 2rem;
  cursor: pointer;
  @extend %sans-serif-bold;
  span {
    color: $yellow;
    font-size: 1.2rem;
    &:after {
      content: "↓";
    }
  }
}

.accordion-box {
  background: $yellow;
  padding: 1rem 2rem;
  overflow: hidden;
  opacity: 1;
  transition: all 400ms ease-in-out;
  transform: translateY(-80px);

  .flexbox__item--middle & {
    transform: translateY(-400px);
  }

  .accordion-box__content {
    height: 100%;
  }

  .timeline__accordion & {
    &.is-collapsed {
      max-height: 0;
      opacity: 0;
    }
    max-height: 80rem;

    @media (min-width: $small-breakpoint) {
      //min-height: 25rem;
      max-height: 50rem;
    }
  }
}

.flexbox__collapse {
  //height: 20rem;

  @media (min-width: $small-breakpoint) {
    //height: 26rem;
    width: 110%;
  }
  z-index: 1000;
}

.accordion-box-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
}

.accordion--we-are {
  cursor: pointer;
}

.is-collapsed {
  visibility: hidden;
  opacity: 0;
}

.flex-video--small {
}

// Cluster

.timeline__cluster {
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  p {
    width: 65%;
    margin: 0;
  }
  p span {
    display: block;
  }
  &--box-1 {
    flex: 1 1 50%;
    display: flex;
    padding-left: 2rem;
    flex-direction: column;
    align-items: center;
  }
  &--box-2 {
    flex: 1 1 50%;
    padding-left: 6rem;
    flex-direction: column;
    align-items: center;
  }
  &--box-3 {
    padding-top: 3rem;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
  }
  &--box-4 {
    flex-direction: column;
    padding-top: 2rem;

    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 3rem;
    padding-left: 3rem;
  }
  &--box-5 {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    flex-direction: column;
  }
  &--box-6 {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
  }
}

.timeline__cluster--box-6-1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.timeline__cluster--box-6-2 {
  padding-left: 6rem;
}

.timeline__cluster-sun {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;

  display: flex;
  flex-wrap: wrap;
  h3 {
    //line-height: 1.2;
    font-size: 1.4rem;
    @extend %serif-black-italic;
    width: 73%;
    margin: 0;
  }
  .timeline__cluster-sun--box {
    background: $yellow;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    transform: translate(-50%, -50%);

    left: 50%;
    width: 18rem;
    height: 18rem;
    z-index: -10;
  }
  &--1 {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
  }
  &--2 {
    flex: 1 1 50%;
    text-align: center;
  }
  &--3 {
    padding-top: 3rem;
    flex: 1 1 50%;
    display: flex;
    text-align: center;
  }
  &--4 {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 2.5rem;
  }
  &--5 {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &--6 {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.timeline__cluster-sun--6-1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding-bottom: 3rem;
  text-align: center;
}

.timeline__cluster-sun--6-2 {
  padding-left: 3rem;
  text-align: center;
}

// Timeline Cluser Slider

.timeline__cluster--small {
  position: relative;
}

.timeline-slider__sun {
  position: absolute;
  background: $yellow;
  height: 20rem;
  width: 20rem;
  border-radius: 100%;
  top: 60%;
  left: -10%;
  transform: translate(-50%, -50%);
}

.timeline-slider {
  background: none;
  margin-bottom: 8rem;
  margin-top: 4rem;
  .relative {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    h3 {
      text-align: center;
    }
    @media (min-width: $small-breakpoint) {
      padding: 0;
      h3 {
        text-align: left;
      }
    }
  }
}

.timeline-slider__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vh;
  h3 {
    text-align: center;
    @extend %serif-black-italic;
  }
}

.timeline__bar {
  height: 72.9%;
  position: absolute;
  width: 0.3rem;
  background: $black;
  left: 5%;
  margin-left: -0.15rem;
  z-index: 0;
  margin-top: 1.3rem;
  @media (min-width: $small-breakpoint) {
    left: 50%;
    height: 71.2%;
  }
}

// Social Feed

.juicer-feed__title {
  span {
    display: block;
    background: $yellow;
    position: relative;
    vertical-align: middle;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: lowercase;
    @extend %serif-book-italic;
    color: $white;
    padding: 0.3rem 0;
    margin-bottom: 1rem;
  }
}

.j-poster {
  display: none;
}

.j-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 5px !important;
  padding-left: 15px !important;
  padding-right: 5px !important;
}

.j-image {
  position: relative;
}

.j-read-more,
.juicer-button,
.referral {
  display: none !important;
}

.juicer-feed .j-meta a {
  color: $white !important;
}

.j-close {
  border-bottom: none;
}

.feed-item {
  overflow: hidden;
  max-height: 185px;
  margin-bottom: 1rem !important;

  @media (max-width: $small-breakpoint) {
    max-height: 235px;
  }
  &:after {
    content: "";
    background: black;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
}

.j-meta {
  width: 100%;
  z-index: 1000;
  position: relative;
  ul {
    display: flex;
    justify-content: space-between;
  }
}

#j-column-0 {
  @media (max-width: $small-breakpoint) {
    .feed-item {
      display: none;
    }
    .feed-item {
      &:first-child {
        display: block;
      }
    }
  }
  @media #{$medium-up} {
    .feed-item {
      height: 230px !important;
      img {
        height: 100% !important;
        object-fit: cover !important;
      }
    }
    .feed-item {
      &:first-child {
        height: 145px !important;
        img {
          height: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }
}

#j-column-1 {
  @media (max-width: $small-breakpoint) {
    .feed-item {
      display: none;
    }
    .feed-item {
      &:first-child {
        display: block;
      }
    }
  }
  @media #{$medium-up} {
    .feed-item {
      height: 145px !important;
      img {
        height: 100% !important;
        object-fit: cover !important;
      }
    }
    .feed-item {
      &:first-child {
        height: 230px !important;
        img {
          height: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }
}

// We are changing the rules -- template design

.template__sub {
  //line-height: 1.2;
  font-size: 1.4rem;
  @extend %serif-black-italic;
  @media #{$medium-up} {
    font-size: 1.6rem;
  }
  @media #{$large-up} {
    font-size: 1.8rem;
  }
  &--centered {
    text-align: center;
  }
}
.margin--top-3 {
  @media #{$medium-up} {
    margin-top: -3rem;
  }
}
.flexbox__container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 1rem auto;

  @media #{$medium-up} {
    flex-direction: row;
    width: 100%;
  }

  &--center-items {
    align-items: center;
    justify-content: center;
  }
}

.flexbox__item {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  min-width: 1px;
  &--column-2 {
    flex-direction: column;
  }
  &--middle {
    flex: 1 0 30%;
  }
  &--single {
    flex: 0 1 30%;
  }
}

.flexbox__collapse {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: left;
}

.shape__blocker {
  width: 2rem;
  height: 100%;

  &--left {
    shape-outside: polygon(100% 0, 100% 0%, 100% 100%, 0% 100%);
    float: right;
  }

  &--right {
    shape-outside: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    float: left;
  }
}

.align--right {
  text-align: right;
}

.angle {
  height: 10rem;
  width: 0.1rem;
  background: $black;
  transform: skew(-10deg);
  position: relative;
  left: 20px;
  @media #{$medium-up} {
    height: 75%;
    transform: skew(-9deg);
  }
  &.angle__top-item {
    @media #{$medium-up} {
      height: 100%;
    }
  }
  &.angle__bottom-item {
    @media #{$medium-up} {
      height: 100%;
    }
  }
}

.reveal-modal {
  padding: 0;
}

#newsletter-modal {
  padding-bottom: 2rem;
}

// Get rid of ugly focus outline
*:focus {
  outline: none;
}

;@import "sass-embedded-legacy-load-done:43";