.tube-logo {
  display: block;
  margin: 0 auto;
  text-decoration: none;
  border: none;
  &.entry {
    max-width: rem-calc(500px);
  }

  &.sub {
    max-width: rem-calc(125px);
    @media #{$medium-up} {
      max-width: rem-calc(200px);
    }
  }
}
.main-content--tube-entry {
  h1 {
    line-height: 0.9;
    margin-bottom: $base-line;
  }
  .richtext {
    padding-top: 1rem;

    p {
      font-size: 1.1rem;
    }
  }
}
.tube-buttons {
  .button {
    border-radius: 0;
    @extend %sans-serif-bold;
    text-transform: uppercase;
  }
}
.tube-logo-wrapper + .richtext.content-type p:last-child {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
}
.flex-mobile-reordering {
  > div:nth-child(3) {
    @media #{$small-only} {
      order: 1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:50";