@import "foundation/scss/foundation/functions";

@import "settings";
@import "foundation";

@import "slick";
@import "slick-theme";
@import "shariff";

// --- custom imports
@import "extension"; // global extension classes
@import "accordion";
@import "offcanvas";
@import "typography";
@import "header";
@import "footer";
@import "pesco";
@import "responsive-table";
@import "timeline";
@import "cms_only";
@import "spinner";
@import "layout";
@import "teasers";
@import "flexbox-grid";
@import "video-tile";
@import "slider";
@import "we-share";
// @import "cookie-consent";
@import "cookie_control_panel";
@import "tube";
@import "cleverreach-forms";

// --- temporary
.show-breakpoint {
  position: fixed;
  right: 0;
  bottom: 40vh;
  > div {
    padding: 2em;
  }
  .show-for-small-only {
    background: none;
  }
  .show-for-medium-only {
    background: orange;
  }
  .show-for-large-only {
    background: red;
  }
}

// --- temporary end

.edit-tools {
  display: none;

  .button {
    margin-bottom: 0;
  }

  @media #{$small} {
    bottom: 0;
    display: block;
    position: fixed;
    right: 0;
  }
}

// --- infographic in popup on home page
.infographic-large {
  padding-top: rem-calc(20);
  width: 300px;
}

;@import "sass-embedded-legacy-load-done:0";