// Default Variables

$slick-font-path: "#{$static-url}fonts/";
$slick-font-family: "FontAwesome" !default;
$slick-loader-path: "frontend/static/images/";
$slick-arrow-color: $grey-medium !default;
$slick-arrow-background: $white !default;
$slick-arrow-fontsize: 24px;
$slick-arrow-size: 40px;
$slick-margin: 10px;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 24px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff url("static/images/ajax-loader.gif") center center
      no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src:
      slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
      slick-font-url("slick.woff") format("woff"),
      slick-font-url("slick.ttf") format("truetype"),
      slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  z-index: 2;
  height: $slick-arrow-size;
  width: $slick-arrow-size;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -($slick-arrow-size * 0.5);
  padding: 0;
  border: none;
  outline: none;
  background-color: $slick-arrow-background;
  border-radius: $slick-arrow-size * 0.5;
  box-shadow: rgba(#000, 0.1) 0 0 10px 0;
  &:hover,
  &:focus {
    outline: none;
    background-color: $slick-arrow-background;
    //background: transparent;
    //color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: $slick-font-family;
  //font-family: FontAwesome;
  content: "\f054";
  font-size: $slick-arrow-fontsize;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: $slick-margin;
  [dir="rtl"] & {
    left: auto;
    right: $slick-margin;
  }
  &:before {
    content: $slick-prev-character;
    margin-left: -4px;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
  @media #{$large} {
    left: -($slick-margin * 5);
  }
}

.slick-next {
  right: $slick-margin;
  [dir="rtl"] & {
    left: $slick-margin;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    margin-left: 4px;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
  @media #{$large} {
    right: -($slick-margin * 5);
  }
}

/* Dots */

.slick-slider {
  margin-bottom: 30px;
  background: $white;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slick-slide .image-description {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

;@import "sass-embedded-legacy-load-done:30";