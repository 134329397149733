/*
* Header
* Offcanvas-Styles are in a separate file '_offcanvas.scss'
*/
@import "settings";

$header-height-small: rem-calc(75);
$header-height-large: rem-calc(95);
$active-highlight-height: 0.15rem;
$dropdown-padding-top: $header-height-large + $active-highlight-height;

// margins
$general-margin-text: 1.5rem;
$general-margin: (4rem - $general-margin-text);
$general-margin-mobile: (3rem - $general-margin-text);

// Header
.main-nav-header {
  background: $white;
  //border-bottom: thin solid $black;
  position: relative;
  z-index: 30;
  @media #{$small-only} {
    height: 8rem;
  }
}

.nav-control {
  @media #{$large-up} {
    height: 8rem;
    margin-top: 2rem;
  }
}

.logo-header {
  height: $dropdown-padding-top * 1.5;
  //background: url(static/images/logo_header-ret.png) no-repeat;
  background-size: 100% auto;
  display: block;
  position: absolute;
  border: none;
  z-index: 2000;
  width: rem-calc(130);
  margin-top: rem-calc(15);
  img {
    max-width: 100%;
    display: block;
  }
  @media #{$large-up} {
    width: rem-calc(140);
    margin-top: rem-calc(20);
  }
}

.active__page {
  position: absolute;
  bottom: 0.5rem;
  @extend %highlight-font-medium;
  font-size: 1.375rem;
  text-transform: uppercase;
  a {
    border-bottom: $active-highlight-height solid $black;
  }
}

// DROPDOWN
// main-nav ul
.main-nav {
  @extend %highlight-font-medium;
  position: relative;
  width: 100%;
  line-height: rem-calc(95);
  font-size: rem-calc(18);
  margin-left: rem-calc(80);
  text-transform: uppercase;
  z-index: 1000;
  letter-spacing: 0.04rem;
  > li {
    float: left;
    position: relative;
    padding-right: $base-line * 1;
    max-height: 65px;
    z-index: 1000;
    display: block;

    &.active > a {
      color: $black;
      border-bottom: $active-highlight-height solid $black;
    }
    > a {
      border-bottom: none;
      //background: $white;
      //color: $grey-light-font;
      color: $grey-dark;
      //display: block;
      position: relative;
      z-index: 2000;
      &:hover,
      &.hover {
        color: $grey-light-font;
        border-bottom: $active-highlight-height solid $black;
      }
    }
  }

  @media #{$large-up} {
    font-size: rem-calc(19);
    > li {
      padding-right: $base-line * 1.5;
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// dropdown ul
.main-nav-dropdown {
  @extend %highlight-font-thin;
  display: none;
  font-size: rem-calc(16);
  padding-top: $dropdown-padding-top;
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  z-index: 1000;
  padding-left: 1rem;
  margin-left: -1rem;
  background: $white;
  > li {
    padding: 0.5rem 1rem 0.5rem 0;
    display: block;
    z-index: 1000;
    &:first-child {
      padding-top: 0.5rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }
    &.active > a {
      border-bottom: 2px solid $black;
    }
    > a {
      display: block;
      background: $white;
      border: none;
      width: fit-content;
      //color: $grey-light-font;
      color: $black;
      border-bottom: 2px solid transparent;
      z-index: 1000;

      &:hover {
        border-bottom: 2px solid $black;
      }
    }
  }
}

// DROPDOWN END

.language-select-btn {
  i {
    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.meta-nav-wrap {
  float: right;
  position: relative;
  z-index: 10000;

  .meta-nav__dropdown {
    padding-left: 1rem;
  }

  .meta-nav__children {
    display: none;
    margin: 0;
    position: absolute;
    top: 5rem;
    background: $white;
    left: 0;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        border-bottom: 2px solid transparent;
        text-transform: capitalize;

        &:hover {
          border-bottom: 2px solid $black;
        }
      }
    }

    &.active {
      display: block;
    }
  }
}

.meta-nav {
  line-height: $header-height-large;
  margin-bottom: -$base-line * 1.1;
  display: inline-block !important;
  font-size: rem-calc(19);
  @extend %highlight-font-thin;
  text-transform: lowercase;

  li {
    margin: 0 rem-calc(14) 0 0;
    &:last-child {
      margin: 0;
    }
    a {
      border: none;
      color: $black;
      &:hover {
        opacity: 0.7;
      }
      .fa-chevron-down {
        font-size: rem-calc(11);
      }
    }
  }
  @media #{medium-up} {
    margin-bottom: 0;
  }
}

.meta-nav__newsletter {
  @extend %highlight-font-medium;
  text-transform: uppercase;
  z-index: 1000;
  position: relative;
  a {
    margin-left: 0.5rem;
    background: $yellow;
    padding: 0.2rem;
    color: $black;
    &:hover {
      opacity: 0.7;
    }
    @media #{$medium-up} {
      padding: 0.8rem;
    }
  }
}

.fa-shopping-cart {
  font-size: rem-calc(17);
  margin-right: 0.3rem;
}

.open-nav {
  line-height: $header-height-small;
  color: $black;
  font-size: rem-calc(18);
  border: none;
  background: url(static/images/bar.svg) no-repeat;
  background-size: 100% auto;
  height: 2rem;
  width: 2.2rem;
  display: block;
  margin-left: auto;

  @media #{$medium-up} {
    line-height: $header-height-large;
  }
  &:hover,
  &:visited,
  &:focus {
    color: $black;
  }
}

// language select
.language-select-btn {
  .fa {
    transition: all 0.1s linear;
    font-size: rem-calc(14);
  }
  &.open {
    color: $black;
  }
  &.open .fa {
    transform: rotate(180deg);
  }
  text-transform: capitalize;
}

.language-select {
  top: 65px !important;
  border: none;
  margin-left: -0.5rem;
  padding-left: 0.5rem;
  &::before,
  &::after {
    display: none;
    border: none;
    width: 0;
    height: 0;
  }
  li {
    margin: 0;
    background: $white;
    padding: 0.5rem 1rem 0.5rem 0;
    display: block;

    &:first-child {
      padding-top: 0.5rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }
    &:hover {
      background: none;
    }
    a {
      color: $black;
      padding: 0;
      width: fit-content;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $black;
      }
    }
  }
}

// Shopping Bag icon
.bag-icon {
  width: rem-calc(16);
  height: rem-calc(17);
  display: inline-block;
  background: url(static/images/bag-icon.png) center center no-repeat;
  background-size: rem-calc(16) rem-calc(17);
  position: relative;
  top: rem-calc(2);
  &.bag-icon-white {
    background: url(static/images/bag-icon-white.png) center center no-repeat;
    background-size: rem-calc(16) rem-calc(17);
  }
}

.meta-nav__shop {
  text-align: right;
  li a {
    border: none;
    text-transform: uppercase;
    @extend %highlight-font-thin;
    font-size: rem-calc(22);
  }
}

.meta-nav__bar.meta-nav__newsletter {
  font-size: rem-calc(16);
}

.meta-nav__bar {
  margin-top: 2rem;
  margin-right: 1rem;
}

;@import "sass-embedded-legacy-load-done:35";