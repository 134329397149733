// Slider

.slick-slider {
  &.slick-slider--home,
  &.project-slider {
    margin-bottom: 0;
  }
}

.slick-dots {
  .slick-slider--home &,
  .project-slider & {
    margin-left: 0;
    bottom: -10px;
  }

  .project-slider & {
    bottom: 25px;

    @media (min-width: $small-breakpoint) {
      bottom: 10px;
    }
  }
  li.slick-active button::before {
    opacity: 1;
  }

  &.white button::before {
    color: $white !important;
  }

  li {
    button::before {
      opacity: 0.5;
      .slick-slider--home &,
      .project-slider &,
      .timeline-slider & {
        color: $black;
        font-size: 34px;
      }
    }
    button {
    }
  }
}

.slick-prev::before,
.slick-next::before {
  .slick-slider--home &,
  .project-slider &,
  .testimonial-slider &,
  .timeline-slider & {
    font-size: 30px;
    color: $black;
    opacity: 1;
  }
}

.project-slider--first-image {
  transform: translate(0, 10%);

  @media (min-width: $small-breakpoint) {
    transform: translate(0, 5%);
  }
}

.slick-slide__home,
.slick-slide__project {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  span {
    position: relative;
    display: block;
    top: 40%;
    text-align: center;
    @extend %serif-black-italic;
    color: $black;
  }
}

.slick-slide__project {
  span {
    top: 35%;
  }
  @media (min-width: $small-breakpoint) {
    span {
      top: 35%;
    }
  }
}

.slick-slide__home--title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  span {
    position: relative;
    display: block;
    top: 1rem;
    vertical-align: middle;
    text-align: center;
    font-size: 2.5rem;
    @extend %serif-book-italic;
    color: $white;
    font-weight: bold;
    //text-transform: lowercase;
  }
}

.slick-slide__project--title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  span {
    position: relative;
    display: block;
    top: 1rem;
    vertical-align: middle;
    text-align: center;
    font-size: 2.5rem;
    @extend %serif-black-italic;
    color: $white;
  }
}

.slick-slide__project--blue {
  span {
    color: $blue;
  }
}

.testimonial-slider__container {
  position: relative;
  height: 100%;
  //max-height: 440px;
  img {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.testimonial-slider__bar {
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 1.2rem 1rem;
  width: 100%;
  align-items: center;
  @media (max-width: $small-breakpoint) {
    padding: 0.6rem 1rem;
  }
}

.testimonial-slider__name {
  font-size: 1.4rem;
  text-transform: uppercase;
  flex: 2 1 auto;
  @media (max-width: $small-breakpoint) {
    line-height: 1;
  }
  .petrol & {
    color: $yellow;
  }
  .ming & {
    color: $green;
  }
  .red & {
    color: #846b93;
  }
  .olive & {
    color: $black;
  }
  .green & {
    color: $white;
  }
  .darkred & {
    color: $white;
  }
}

.testimonial-slider__title {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: $white;
  flex: 0 1 auto;
  padding-right: 0.5rem;
  span {
    @extend %sans-serif-bold;
  }
}

.testimonial-slider__number {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: $white;
  flex: 0 1 auto;
  span {
    @extend %sans-serif-bold;
  }
}

.project__box {
  position: absolute;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  bottom: 0;
  color: $white;

  @media (max-width: $small-breakpoint) {
    height: 320px;
  }
}

.project__box-title {
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  max-width: 70%;
  @media (min-width: $small-breakpoint) {
    //margin-top: 2.5rem;
  }
}

.project__box-sub {
  font-size: 1.4rem;
  font-weight: bold;
  //line-height: 1;
  text-transform: uppercase;
  border-bottom: 2px solid $white;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  width: 65%;
}

.project__box-description {
  width: 70%;
  font-weight: bold;
  //line-height: 1;
  @media (max-width: $small-breakpoint) {
    display: none;
  }
}

.shop__box {
  position: absolute;
  width: 69%;
  bottom: 3rem;
  left: 50%;
  height: 45%;
  transform: translate(-50%, 0);
  border: 4px solid $yellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media (min-width: $medium-breakpoint) {
    height: auto;
    width: 59%;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(161, 162, 163, 1) 100%
    );
    opacity: 0.2;
  }
}

.shop__box-description {
  text-align: center;
  color: $white;
  border-bottom: 1px solid $white;
  width: 90%;
  transform: translate(5%, 0);
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
  word-break: keep-all;
  text-transform: uppercase;

  @media (min-width: $medium-breakpoint) {
    width: 80%;
    transform: translate(10%, 0);
  }

  @media (max-width: $small-breakpoint) {
    font-size: 85%;
  }
}

.shop__box-title {
  text-transform: uppercase;
  color: $white;
  text-align: center;
  padding: 0.5rem 0;
  z-index: 10;

  @media (max-width: $small-breakpoint) {
    font-size: 150%;
  }
}

.shop__box-action {
  position: relative;
  display: block;
  text-align: center;
  color: $black;
  background: $yellow;
  bottom: 0;
  padding: 0.25rem 0;
  text-transform: uppercase;
  width: 100%;
}
.project-slider__image {
  position: relative;
  height: 451px;
  display: flex;
  img {
    display: block;
    position: absolute;
    top: 5.1rem;
    left: 50%;
    transform: translate(-50%, 0);
    //max-height: 390px;

    .main-style--round & {
      clip-path: circle(50% at 50% 50%);
      width: 24.5rem;
      top: 3.1rem;
      @media (max-width: $small-breakpoint) {
        width: 16.5rem;
      }
    }
  }

  @media (max-width: $small-breakpoint) {
    height: 320px;
  }
}

.project-slider__title {
  span {
    display: block;
    position: relative;
    vertical-align: middle;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    //text-transform: lowercase;
    @extend %serif-book-italic;
    color: $white;
    padding: 0.3rem 0;
    margin-bottom: 1rem;
    line-height: 0.6;
    .main-style--round & {
      color: $blue;
    }
    @media (max-width: $small-breakpoint) {
      font-size: 2rem;
    }
  }
  height: 65px;
  background: $lightgreen;
  .main-style--round & {
    background: none;
  }

  .main-style--quadratic.main-color--pureorange & {
    background: $vividred;
  }
  .main-style--quadratic.main-color--lightgreen & {
    background: $blue;
  }
  .main-style--quadratic.main-color--vividred & {
    background: $pureorange;
  }
  .main-style--quadratic.main-color--darkcyan & {
    background: $blue;
  }
  .main-style--quadratic.main-color--blue & {
    background: $darkcyan;
  }
  .main-style--quadratic.main-color--white & {
    background: $pureorange;
  }
}

.white.slick-prev::before,
.white.slick-next::before {
  color: $white !important;
}

.slick-prev,
.slick-next {
  .slick-slider--home &,
  .project-slider &,
  .testimonial-slider &,
  .timeline-slider & {
    box-shadow: none;
    background: none;
  }
}

.slick-prev {
  .slick-slider--home & {
    left: 0;
    background: none;
  }
}

.slick-next {
  .slick-slider--home & {
    right: 0;
    background: none;
  }
}

.slick-prev {
  .project-slider & {
    top: 55%;
    left: 0px;
    background: none;
    @media (max-width: $small-breakpoint) {
      top: 55%;
      left: -10px;
      background: none;

      &:before {
        color: $black !important;
      }
    }
    @media (min-width: $medium-breakpoint) {
      top: 55%;
      left: 30px;
      background: none;
    }
  }
}

.slick-next {
  .project-slider & {
    top: 55%;
    right: 0px;
    background: none;
    @media (max-width: $small-breakpoint) {
      top: 55%;
      right: -10px;
      background: none;

      &:before {
        color: $black !important;
      }
    }
    @media (min-width: $medium-breakpoint) {
      top: 55%;
      right: 30px;
      background: none;
    }
  }
}

.slick-prev {
  .testimonial-slider & {
    left: 10px;
    background: none;
  }
}

.slick-next {
  .testimonial-slider & {
    right: 10px;
    background: none;
  }
}

.slick-prev {
  .timeline-slider & {
    left: -30px;
    background: none;
  }
}

.slick-next {
  .timeline-slider & {
    right: -30px;
    background: none;
  }
}

.slick-dots {
  .timeline-slider & {
    margin-left: 0;
  }
}

;@import "sass-embedded-legacy-load-done:47";