.content-type.type--accordion {
  padding-top: 0;
  margin-bottom: 2rem;
}

.accordion {
  .mediafile {
    padding: 0;
    margin-bottom: 1.5rem;
  }
}

.accordion {
  .accordion-navigation {
    > a {
      position: relative;
      border-bottom: none;
      padding-right: 3rem;

      @extend .FFDINStdCondensedBlack;
      font-size: 1.5rem;

      icon {
        @extend .FFDINStdCondensedMedium;
        position: absolute;
        right: 0.5rem;
        top: calc(50% - 1.2rem);
        font-size: 3rem;
        font-weight: normal;
        line-height: 0.6;
      }
    }

    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    &.active {
      border-top: 2px solid $accordion-navigation-hover-bg-color;
      // border-top: 2px solid $primary-color;
      icon {
        right: 0.7rem;
        &::before {
          content: "–";
        }
      }
    }

    // &.active + .accordion-navigation {
    //   border-top: 2px solid $primary-color;
    // }

    border-bottom: 2px solid $primary-color;
    &:not(.active) {
      &:hover,
      &:focus-visible {
        border-top: 2px solid $accordion-navigation-hover-bg-color;
        border-bottom: 2px solid $primary-color;
      }
      icon {
        transform: rotate(45deg);
        &::before {
          content: "×";
        }
      }
      &:first-child {
        border-top: 2px solid $primary-color;
      }
      &:last-child {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:32";