/*
* Offcanvas Navigation
* https://scotch.io/tutorials/off-canvas-menus-with-css3-transitions-and-transforms
*/
@import "settings";

/*
* Offcanvas Behaviour
*/

$offcanvas-width: 300px;

// this is necesary to allow scrolling if nav is closed
body {
  height: auto !important;
}

// disable scrolling if navigation is open
// .show-nav {
//   body {
//     overflow: hidden;
//   }
// }

#outer-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  .show-nav & {
    height: 100%;
  }
}

#inner-wrapper {
  width: 100%;
  position: relative;
  transition: 300ms ease all;
  backface-visibility: hidden;

  .show-nav & {
    transform: translate3d(-$offcanvas-width, 0, 0) scale3d(1, 1, 1);
    @media #{$large-up} {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }
}

.offcanvas-menu {
  transform: translate3d($offcanvas-width, 0, 0);
  width: $offcanvas-width;
  position: absolute;
  top: 0;
  transition: 300ms ease all;
  right: 0;
  height: 100%;
  overflow-y: auto;
  .show-nav & {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

/*
* Offcanvas Styling
*/
.offcanvas-menu {
  background: $grey-dark;
  padding-top: 1.5rem;
  a {
    border: none;
  }
  li a {
    color: $grey-light-font;
    &:hover {
      color: $black;
    }
  }
}

.offcanvas-main-nav {
  background: $black;
  > li {
    a {
      display: block;
      padding: $base-line * 0.5;
      border-bottom: thin solid darken($grey-medium, 20%);
      color: $grey-light-font;
      &:hover {
        color: $grey-light;
      }
      span {
        padding-left: $base-line * 0.25;
      }
    }
  }
  > li.active > a {
    color: $grey-light-font;
  }
}

.offcanvas-main-nav-dropdown {
  //@extend %sans-serif-regular;
  background: $grey-dark;
  display: none;
  > li {
    a {
      display: block;
      padding: $base-line * 0.5;
      border-bottom: thin solid darken($grey-medium, 20%);
      color: $grey-light-font;
      padding-left: $base-line;
      &:hover {
        background: $primary-color;
        color: $black;
      }
    }
  }
  > li.active > a {
    background: $grey-dark;
    color: $primary-color;
  }
}

// offcanvas icon
.fa-angle-right {
  transition: all 0.1s;
  transform: rotate(0);
}
.fa-angle-right.rotated {
  transform: rotate(90deg);
}

.offcanvas-lang-nav {
  padding-bottom: $base-line * 1.1;
  border-bottom: thin solid darken($grey-medium, 20%);
  li {
    a {
      font-size: 0.8rem;
      padding: 0 $base-line * 0.5;
      color: $grey-light;
      &:hover {
        color: $white;
      }
    }
  }
}
.offcanvas-meta-nav {
  li {
    width: 100%;
    margin: 0;
    border-bottom: thin solid darken($grey-medium, 20%);
    a {
      font-size: rem-calc(14);
      display: block;
      padding: $base-line * 0.5;
      color: $grey-light;
      &:hover {
        color: $white;
      }
    }
  }
}

.close-nav {
  float: right;
  padding: 0 1rem;
  color: $grey-light;
  &:hover {
    color: $white;
  }
}

;@import "sass-embedded-legacy-load-done:33";