@use "sass:math";

@import "settings";

// Pesco Modal
.modal-icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;
  bottom: -0.5rem;
  &.icon-one {
    background: url(static/images/icon-one.png) center center no-repeat;
    background-size: 100% auto;
  }
  &.icon-two {
    background: url(static/images/icon-two.png) center center no-repeat;
    background-size: 100% auto;
  }
  &.icon-three {
    background: url(static/images/icon-three.png) center center no-repeat;
    background-size: 100% auto;
  }
  &.icon-four {
    background: url(static/images/icon-four.png) center center no-repeat;
    background-size: 100% auto;
  }
}

// Pesco Infografik Link
.infographic-link {
  position: relative;
  top: rem-calc(-18);
}

.actions-container {
  background-color: $grey-light-bg;
  //padding-top: 4rem;
}

.tile-action-button {
  //font-size: rem-calc(17);
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 1.25rem 2.5rem;
}

.action-filter-wrap {
  margin-top: 2rem;
  .filter,
  .tabs .filter.secondary {
    font-size: rem-calc(14);
  }
}

.action-filter-buttons {
  button {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size: rem-calc(14);
  }
}

// action filters
.tabs .tab-title {
  margin-right: rem-calc(5);
}

.tabs .filter.secondary,
button.secondary,
button.secondary:focus,
.filter-button {
  background-color: $white;
  color: $grey-dark;
  border-radius: 3px;
  font-size: rem-calc(14);
  @media #{$medium-up} {
    font-size: rem-calc(16);
  }

  &:hover,
  &.active,
  &.active:focus {
    background: $grey-dark;
    color: $white;
  }
}

.filter-button.dropdown {
  padding: 0.8125rem 1.625rem;
  line-height: inherit;
}

button.secondary.button-grey,
button.secondary.button-grey:focus,
.button.secondary.button-grey,
.button.secondary.button-grey:focus {
  background-color: $grey-light;
  color: $grey-dark;

  &:hover,
  &.active,
  &.active:focus {
    background: darken($grey-light, 10%);
    color: $black;
  }
}

.action-view-filter {
  a {
    border: none;
  }
}

.action-button-icon {
  width: rem-calc(15);
  height: rem-calc(15);
  display: inline-block;
  position: relative;
  top: rem-calc(1);
  background: url(static/images/ring-small-phase-one.png) center center
    no-repeat;
  background-size: rem-calc(15) rem-calc(15);
  &.phase_1 {
    background: url(static/images/ring-small-phase-one.png) center center
      no-repeat;
    background-size: rem-calc(15) rem-calc(15);
  }
  &.phase_2 {
    background: url(static/images/ring-small-phase-two.png) center center
      no-repeat;
    background-size: rem-calc(15) rem-calc(15);
  }
  &.phase_3 {
    background: url(static/images/ring-small-phase-three.png) center center
      no-repeat;
    background-size: rem-calc(15) rem-calc(15);
  }
  &.phase_4 {
    background: url(static/images/ring-small-phase-four.png) center center
      no-repeat;
    background-size: rem-calc(15) rem-calc(15);
  }
}

#action-filter-dropdown {
  li {
    border-bottom: thin solid $grey-light-bg;
    button {
      width: 100%;
      margin-bottom: 0;
      text-align: left;
    }
  }
  &::before,
  &::after {
    display: none;
    border: none;
    width: 0;
    height: 0;
  }
}

.actions-list-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;

  &.active {
    display: flex !important;
  }
}
.action-tile-spacer {
  display: flex;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: $base-line;
  flex-shrink: 1;
  flex-basis: 100%;
  @media #{$tablet-up} {
    flex-basis: auto;
    width: 50%;
  }
  @media #{$large-up} {
    flex-basis: auto;
    width: 33.3%;
  }
}
.action-tile {
  background-color: white;
  width: calc(100% - 2px); // IE11 bug
}

.action-nav {
  display: flex;
  font-size: rem-calc(14);
  margin: 0 0 2rem;
  @media #{$medium-up} {
    font-size: rem-calc(16);
  }
  @media #{$large-up} {
    margin: 1rem 0 2rem;
    max-width: rem-calc(700);
  }

  @media #{$phone-only} {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  li {
    margin-left: rem-calc(1);
    width: 100%;

    @media #{$phone-only} {
      width: 49.6%;
      margin-bottom: 0.15rem;
    }

    text-align: center;
    &:first-child {
      width: rem-calc(65);

      @media #{$phone-only} {
        width: 49.6%;
      }

      min-width: rem-calc(65);
      margin-left: 0;
      a {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
      }
    }
    a {
      padding: 1rem;
      height: rem-calc(55);
      @media #{$medium-up} {
        height: rem-calc(57);
      }
      @media #{$large-up} {
        height: rem-calc(60);
        border-radius: rem-calc(3);
      }
      border-bottom: none;
      display: block;
      background: $white;
      &:hover,
      &.active {
        background-color: $phase_2-color;
        color: $white;
      }
      .phase_1 &:hover,
      .phase_1 &.active {
        background-color: $phase_1-color;
      }
      .phase_2 &:hover,
      .phase_2 &.active {
        background-color: $phase_2-color;
      }
      .phase_3 &:hover,
      .phase_3 &.active {
        background-color: $phase_3-color;
      }
      .phase_4 &:hover,
      .phase_4 &.active {
        background-color: $phase_4-color;
      }
    }
  }
}

// Filters
.no-phase_2 .phase_2 {
  display: none;
}
.no-phase_1 .phase_1 {
  display: none;
}
.no-phase_3 .phase_3 {
  display: none;
}
.no-phase_4 .phase_4 {
  display: none;
}

// Map
#map-container {
  width: 100%;
  height: 30rem;

  @media #{$medium-up} {
    height: 50rem;
  }
}

$grey-moodboard: $black;

@import "tile";
.action-moodboard-wrap {
  background: $grey-moodboard;
  max-width: rem-calc(1440);
  margin: 0 auto;
}

.action-moodboard {
  position: relative;
  background: $grey-moodboard center 20% no-repeat;
  background-size: cover;
  height: 20rem;
  color: white;
  margin: 0 auto;

  @media #{$medium-up} {
    height: 30rem;
  }
  @media #{$large-up} {
    height: 35rem;
  }
}
.action-moodboard-gradient {
  display: none;
  @media #{$medium-up} {
    display: block;
    //background: linear-gradient(to right, rgba($grey-moodboard,1.0) 0%,rgba($grey-moodboard,0.0) 31%,rgba($grey-moodboard,0.0) 66%,rgba($grey-moodboard,1.0) 100%);
    //background: linear-gradient(to top, rgba($grey-moodboard,1.0) 0%,rgba($grey-moodboard,0.0) 80%);
    //background: linear-gradient(to top, rgba($grey-moodboard,0.8) 0%,rgba($grey-moodboard,0.0) 80%), linear-gradient(to right, rgba($grey-moodboard,1.0) 0%,rgba($grey-moodboard,0.0) 31%,rgba($grey-moodboard,0.0) 66%,rgba($grey-moodboard,1.0) 100%);
    //background: linear-gradient(to right, rgba($grey-moodboard,0.6) 0%,rgba($grey-moodboard,0.1) 100%);
    background: linear-gradient(
      to right,
      rgba($grey-moodboard, 0.6) 0%,
      rgba($grey-moodboard, 0.1) 60%
    );
  }
  &.radial-gradient {
    //background: radial-gradient(ellipse at 47% 45%, rgba($black,0.0) 47%, rgba($black,1.0) 103%);
    background: radial-gradient(
      ellipse at 47% 45%,
      rgba($grey-moodboard, 0) 47%,
      rgba($grey-moodboard, 0.8) 103%
    );
  }
}
.action-moodboard-text {
  z-index: 2;
  position: absolute;
  bottom: 0;
}
.action-title {
  @extend %highlight-font-thin;
  font-size: $h2-font-size;
  line-height: 1.2;
  margin-bottom: $base-line * 0.5;
  font-weight: $font-weight-bold;
  position: absolute;
  bottom: 0;
  z-index: 2;
  //padding: $base-line/2 $base-line/1.5;
  padding: rem-calc(3) rem-calc(15);
  a {
    border: none;
    color: $white;
    text-shadow: 2px 2px 10px $black-transparent;
  }
}

// Detailseite
.button.active {
  background: white;
}

#action-map {
  width: 100%;
  height: rem-calc(400px);
}

@mixin section-action-color($darkColor, $lightColor, $adjustment) {
  button,
  .button {
    background: $darkColor;
    border-radius: rem-calc(3);
    color: $white;
    font-weight: 300;
    &:hover {
      background: lighten($darkColor, $adjustment);
    }
    &.light-button {
      background: $lightColor;
      &:hover {
        background: lighten($lightColor, $adjustment);
      }
    }
  }
  .call-to-action {
    background: $lightColor;

    .cta-donation-missing {
      background: $lightColor;
    }
  }

  .panel {
    background: rgba($lightColor, 0.12);
  }

  .checkout-status-item.active > .status-link,
  .donation-widget-trigger {
    color: $lightColor;
  }

  .cta-donation-missing {
    background-color: $lightColor;
  }
  .donation-choice,
  .reward-choice {
    color: $lightColor;
    background-color: $grey-light-bg;
  }
  .donation-choice:hover,
  .donation-choice:focus,
  .reward-choice:hover,
  .reward-choice:focus {
    background-color: $lightColor;
    color: $white;
  }

  .reward-choice[disabled],
  .reward-choice[disabled]:hover {
    margin-top: 1px;
    background-color: $grey-light-bg;
    color: $grey-light-font;
  }

  .donation-widget {
    .button {
      background: $lightColor;
      &:hover {
        background: lighten($lightColor, 8%);
      }
    }
  }
}

.phase_1 {
  @include section-action-color($phase_1-color-dark, $phase_1-color, 6%);
}

.phase_2 {
  @include section-action-color($phase_2-color-dark, $phase_2-color, 10%);
}

.phase_3 {
  @include section-action-color($phase_3-color-dark, $phase_3-color, 5%);
}

.phase_4 {
  @include section-action-color($phase_4-color-dark, $phase_4-color, 5%);
}

.call-to-action {
  padding: $base-line 0;

  &.bg-white {
    background-color: $white;
    .cta-claim,
    .cta-subtext,
    .cta-likes {
      color: $black;
    }
  }
}

.cta-title-wrap {
  padding: 0 rem-calc(20);
}
.cta-bottom-wrap {
  padding: rem-calc(20) rem-calc(20) 0;
}

.cta-subtext {
  color: $white;
  text-align: center;
}

.cta-donation-list {
  background: $white;
  list-style: none;
  margin-left: 0;
  padding: 0 rem-calc(20);
  margin-bottom: 0;

  li {
    border-bottom: thin solid $grey-light;
    &:last-child {
      border-bottom: thin solid $white;
    }
  }
}

.cta-label {
  @extend %sans-serif-bold;
  margin: 0;
  padding: 0.7rem 0 0.3rem;
}

.cta-donation-title {
  @extend h3;
  margin-top: 0;
  margin-bottom: 0.2rem;
}

.cta-donation-missing {
  @include label(
    $padding: 0.2rem 0.5rem,
    $text-size: $base-font-size,
    $bg: $primary-color,
    $radius: $global-rounded
  );
  margin-left: 0.5rem;
  font-weight: $font-weight-thin;
}

.cta-likes {
  padding: 1rem 0;
  display: block;
  color: $white;
  .bg-white & {
    color: $black;
    text-align: center;
  }
}

// Detailseite Sidebar
.aside-block {
  margin-bottom: $base-line;
  hr {
    margin: 0;
  }
}
.aside-section {
  padding: $base-line 1rem;
  p {
    width: 100%;
    margin: 0;
  }
}

.aside-phase {
  background: $phase_1-color;
  display: flex;
  color: $white;
  a {
    color: $white;
  }
  .phase_1 & {
    background: $phase_1-color;
  }
  .phase_2 & {
    background: $phase_2-color;
  }
  .phase_3 & {
    background: $phase_3-color;
  }
  .phase_4 & {
    background: $phase_4-color;
  }
}

.aside-infographic {
  width: rem-calc(60);
  min-width: rem-calc(60);
  height: rem-calc(60);
  background: url(static/images/ring-phase-one.png) center center no-repeat;
  .phase_1 & {
    background: url(static/images/ring-phase-one.png) center center no-repeat;
  }
  .phase_2 & {
    background: url(static/images/ring-phase-two.png) center center no-repeat;
  }
  .phase_3 & {
    background: url(static/images/ring-phase-three.png) center center no-repeat;
  }
  .phase_4 & {
    background: url(static/images/ring-phase-four.png) center center no-repeat;
  }
}

.aside-partner-wrap {
  margin: rem-calc(10) 0;
  img {
    @media #{$medium-down} {
      max-width: rem-calc(300);
    }
  }
}
.share {
  a {
    border: none;
  }
}

// Detailseite Donation
$trigger-width: 3rem;
.donation-widget {
  margin-bottom: 0.5rem;
}
.donation-widget-holder {
  //background-color: $grey-light;
  background-color: $white;
}
.donation-widget-header {
  display: flex;

  &.open .fa-angle-up {
    transform: rotate(0deg);
  }
}
.donation-widget-trigger {
  display: block;
  width: $trigger-width;
  text-align: center;
  padding-top: $base-line;
  border: none;
}
.donation-widget-lead {
  flex: 1;
  padding: $base-line * 0.5 $base-line * 0.5 0 math.div($base-line, 3);
  background-color: white;
}
.donation-widget-body {
  margin-left: $trigger-width;
  background-color: white;
  padding: math.div($base-line, 3) $base-line math.div($base-line, 3)
    math.div($base-line, 3);
}
.accordion-toggle {
  cursor: pointer;
}

// Donation Choice Tabelle

.donation-button-group {
  margin: $base-line 0;
}

.donation-notification {
  font-size: rem-calc(12);
  line-height: 1rem;
  font-weight: 400;
}

.donation-choice,
.reward-choice {
  background-color: $grey-light-bg;
  box-shadow: $grey-medium 0 0 1px 0;
  margin-bottom: rem-calc(5);
  font-size: 1rem;
  color: $body-font-color;
  width: 100%;
  padding: 1rem;
  > p {
    margin-bottom: 0;
    padding: 0 1rem;
    text-align: center;
  }
}

button.reward-choice {
  text-align: left;
  padding: 1rem;
}

.custom-help-text {
  padding-top: 0.5rem;
}

// Donation Checkout
.checkout-status-container {
  background-color: white;
  margin-bottom: $base-line * 0.5;
  padding: $base-line * 0.5 0;
}
.checkout-status-item {
  font-size: rem-calc(18);
  line-height: 2;
  white-space: nowrap;
  text-align: center;

  > .status-link {
    color: $grey-medium;
  }

  &.active > .status-link {
    color: $primary-color;
  }
}

.checkout-start-container {
  display: flex;
  flex-flow: row wrap;
  padding: $base-line;
  @media #{$medium-up} {
    padding: $base-line * 2;
  }
}
.login-container {
  flex-basis: 100%;
}
.registration-container {
  flex-basis: 100%;
}
.table-checkout-summary {
  th {
    vertical-align: top;
    font-weight: $font-weight-bold;
  }
  td {
    @media #{$medium-up} {
      font-size: $base-font-size;
      line-height: $base-line-height;
    }
  }
}

@media #{$medium-up} {
  .login-container,
  .registration-container {
    flex-basis: 50%;
  }
  .login-container {
    border-right: 1px solid $grey-light-bg;
    padding-right: $base-line * 2;
  }
  .registration-container {
    padding-left: $base-line * 2;
  }
}

.color-phase_1 {
  color: $phase_1-color;
}
.color-phase_2 {
  color: $phase_2-color;
}
.color-phase_3 {
  color: $phase_3-color;
}
.color-phase_4 {
  color: $phase_4-color;
}

// User Account
.useraccount-container {
  padding: $base-line;
  .panel {
    &.error {
      background: rgba($alert-color, 0.12);
    }
    &.success {
      background: rgba($success-color, 0.15);
    }
  }
}

;@import "sass-embedded-legacy-load-done:37";