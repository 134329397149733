/*
* global extension classes
*/

button,
.button {
  margin-bottom: 0;
}

.panel {
  border: none;
}

.color-white {
  color: $white;
  &.underline {
    border-bottom: thin solid $white;
  }
}

.color-grey-light {
  color: $grey-light;
}

.bg-white {
  background: $white;
}

.list-no-margin {
  list-style: none;
  margin: 0;
}

.list-inline {
  @extend .list-no-margin;
  li {
    display: inline;
  }
}

.no-margin {
  margin: 0;
}

.no-link {
  border-bottom: none;
}

.append-bottom {
  margin-bottom: $base-line;
}
.append-bottom-2x {
  margin-bottom: $base-line * 2;
}
.prepend-top {
  margin-top: $base-line;
}
.prepend-top-2x {
  margin-top: $base-line * 2;
}

.relative {
  position: relative;
}

.centered {
  text-align: center;
  color: red; // use text-center
}

.box-shadow {
  box-shadow: 0 0 5px #d5d5d5;
}

.flex-1 {
  flex: 1;
}
.flex-4 {
  flex: 4;
}

.flex-row {
  margin-left: -$column-gutter;
}
.flex-column {
  margin-left: $column-gutter;
}
.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.primary-color {
  color: $primary-color;
}

.padder {
  padding: $base-line;
}
.spacer {
  height: $base-line * 2;
  width: 100%;
}

.title-label {
  @extend h3;
}

// Button
.button {
  border-radius: rem-calc(3);
}

button:focus,
.button:focus {
  outline: none;
}

.button.transparent {
  background-color: transparent !important;
  border: 0;
  color: $body-font-color;
  &:hover {
    color: $black;
    background-color: $grey-light !important;
  }
}

@media #{$small-only} {
  button.small-expand,
  .button.small-expand {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

small.error {
  display: none;
  .error & {
    display: inherit;
  }
}

.reveal-modal {
  .close-reveal-modal {
    border: none;
    background: $white;
    width: rem-calc(48);
    height: rem-calc(48);
    border-radius: rem-calc(24);
    text-align: center;
    top: $base-line * 0.5;
    right: $base-line * 0.5;
  }
}

.action-gradient {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    $black-transparent,
    rgba(0, 0, 0, 0.05) 100%
  );
  &.to-bottom {
    background: linear-gradient(
      to top,
      $black-transparent 30%,
      transparent 60%
    );
  }
  &.landingpage {
    background: linear-gradient(
      to top,
      $black-transparent 30%,
      transparent 60%
    );
    @media #{$medium-up} {
      background: linear-gradient(
        to top,
        $black-transparent 25%,
        transparent 50%
      );
    }
    @media #{$large-up} {
      background: linear-gradient(
        to top,
        $black-transparent 15%,
        transparent 40%
      );
    }
  }
}

.shariff.inline {
  .orientation-horizontal {
    flex-flow: row nowrap;
    gap: 4px;
  }

  .shariff-button {
    flex: 0 0 35px;
    min-width: 0 !important;
  }

  .shariff-button,
  a {
    height: 35px !important;
    width: 35px !important;
  }

  .share_text {
    display: none !important;
  }

  a {
    border: none !important;
    display: grid;
    place-items: center;
  }
}

form .helptext {
  font-size: 0.9em;
  color: $grey-dark;
  margin-top: -0.5rem;
}

;@import "sass-embedded-legacy-load-done:31";