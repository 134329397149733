/*
* Timeline
*/

.timeline {
  padding-bottom: $base-line * 3;
}

.text-wrap {
  padding: 1rem 1rem 1.5rem 1rem;
}

.donation-wrap {
  .donation-category {
    background: $grey-light-bg;
    padding: 0.2rem 0.6rem;
  }
  .missing {
    margin-left: 1rem;
    background: $phase_2-color;
    color: $white;
    padding: 0.2rem 1rem;
  }
}

.donation-option {
  border-bottom: thin solid $grey-light-bg;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  h5 {
    margin-bottom: 0;
  }
}

.timeline-box-inner {
  h3.clickable {
    cursor: pointer;
  }
}

// timeline-elements
@mixin timeline-element($phase-color) {
  .timeline-box-inner {
    background-color: $white;
    .timeline-date {
      background-color: $phase-color;
    }
    .timeline-title {
      color: $black;
      // @extend %highlight-font-thin;
      // font-size: rem-calc(34);
    }
  }
  &:after {
    display: none;
    @media #{$medium-up} {
      display: block;
      background-color: $phase-color;
    }
  }
}

@mixin donation-element($phase-color, $phase-color-dark) {
  .timeline-box-inner {
    background-color: $phase-color;
    color: $white;
    .timeline-date {
      background-color: $phase-color-dark;
    }
    a {
      color: $white;
    }
    .timeline-title {
      color: $white;
      // @extend %highlight-font-thin;
      //font-size: rem-calc(34);
    }
    .button {
      background-color: $phase-color-dark;
      color: $white;
      &:hover {
        background-color: lighten($phase-color-dark, 4%);
      }
    }
  }
  &:before {
    display: none;
    @media #{$medium-up} {
      display: block;
      border-color: $phase-color;
    }
  }
  &:after {
    display: none;
    @media #{$medium-up} {
      display: block;
      background-color: $phase-color;
      border-color: $phase-color;
    }
  }
}

.timeline_element.phase_1 {
  @include timeline-element($phase_1-color);
  &.donationObj {
    @include donation-element($phase_1-color, $phase_1-color-dark);
  }
}

.timeline_element.phase_2 {
  @include timeline-element($phase_2-color);
  &.donationObj {
    @include donation-element($phase_2-color, $phase_2-color-dark);
  }
}

.timeline_element.phase_3 {
  @include timeline-element($phase_3-color);
  &.donationObj {
    @include donation-element($phase_3-color, $phase_3-color-dark);
  }
}

.timeline_element.phase_4 {
  @include timeline-element($phase_4-color);
  &.donationObj {
    @include donation-element($phase_4-color, $phase_4-color-dark);
  }
}

.timeline-date {
  @include label(
    $padding: 0.2rem 0.5rem,
    $text-size: $base-font-size,
    $bg: $primary-color,
    $radius: $global-rounded
  );
  font-size: rem-calc(14);
  font-weight: $font-weight-thin;
}

// image in timeline bolx
.timeline_element_box {
  img {
    width: 100%;
  }
}

// timeline modal
#timeline-modal {
  .timeline-date {
    background: $grey-medium;
  }
  &.phase_1 .timeline-date {
    background: $phase_1-color;
  }
  &.phase_2 .timeline-date {
    background: $phase_2-color;
  }
  &.phase_3 .timeline-date {
    background: $phase_3-color;
  }
  &.phase_4 .timeline-date {
    background: $phase_4-color;
  }
  img {
    float: right;
    margin: 0 0 1rem 1rem;
  }
  .timeline-title {
    color: $black;
    // @extend %highlight-font-thin;
    // font-size: rem-calc(34);
  }
}

;@import "sass-embedded-legacy-load-done:40";