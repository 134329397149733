// Teasers

.blog__teaser {
  position: relative;
  &:after {
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5046219171262255) 100%
    );
    background-blend-mode: multiply;
    position: absolute;
    z-index: 0;
  }
}

.blog__subheader {
  position: absolute;
  width: 100%;
  height: 5rem;
  text-align: center;
  padding: 0.6rem 0;
  z-index: 1;

  span {
    color: $yellow;
    border-top: 3px solid $yellow;
    border-bottom: 3px solid $yellow;
    @extend %serif-black-italic;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: normal;
    @media (min-width: $medium-breakpoint) {
      font-size: 2.8rem;
    }
    @media (min-width: $large-breakpoint) {
      font-size: 3.4rem;
    }
  }
}

.teaser__image-container {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 100%;
  }
  //min-height: 340px;
  //@media (min-width: $small-breakpoint) {
  //  min-height: 455px;
  //}
}

.teaser__content {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  color: $white;
  z-index: 10;
}

.teaser__date {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.25rem;
  @media (min-width: $small-breakpoint) {
    margin-bottom: 0.5rem;
  }
}

.teaser__title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;

  padding: 0 0.25rem;

  @media (min-width: $medium-breakpoint) {
    margin-bottom: 0.5rem;
  }
}

.teaser__text {
  padding: 0 0.5rem;
  @media (min-width: $small-breakpoint) {
    padding: 0 2rem;
  }
  @media (max-width: $small-breakpoint) {
    display: none;
  }
  p {
    font-weight: 400;
    margin-bottom: 0.5rem;
    @media (min-width: $small-breakpoint) {
      margin-bottom: 1rem;
    }
  }
}

.teaser__button {
  border: 2px solid $white;
  align-self: center;
  width: 7rem;
  padding: 0.3rem;
  color: $white;
  margin-bottom: 1rem;
  z-index: 100;

  @media (min-width: $small-breakpoint) {
    width: 10rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
  }
  &:hover {
    color: $black;
    background: $white;
  }
}

.teaser__link {
  border-bottom: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.large__teaser {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;

  @media (max-width: $medium-breakpoint) {
  }

  .large__image-wrapper {
    //padding: 0 2rem;
    img {
      //width: 100%;
      max-width: 100%;
      height: 100%;
      .main-content--tube-entry & {
        width: 100%;
        height: auto;
        @media (max-width: $small-breakpoint) {
          max-height: none;
        }
      }
      @media (max-width: $small-breakpoint) {
        max-height: 320px;
      }
    }

    &.rhomboid img {
      clip-path: polygon(5% 25%, 50% 0, 95% 75%, 50% 100%);
    }
  }

  .large__teaser-link {
    border: none;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.large__teaser-title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  span {
    position: relative;
    display: block;
    top: 50%;
    text-align: center;
    @extend %serif-black-italic;
    color: $black;
    transform: translateY(-50%);
  }
}

// Pineapple
.pineapple__container {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 422px;
  width: 100%;
  justify-content: center;
  &.pineapple__container--main {
    display: block;
    height: auto;
    width: auto;
    min-height: auto;
    img {
      position: relative;
      transform: translateX(0);
      min-height: auto;
    }
  }
  img {
    //height: 100%;
    //min-height: 440px;
    position: absolute;
    transform: translateX(-10px);
    width: 100%;
    display: block;
    @media (min-width: $small-breakpoint) {
      min-height: 440px;
    }
  }
}

.pineapple__text {
  position: relative;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(5%);
  //padding: 2rem 0 0 0;
  .pineapple__container--main & {
    transform: translateY(0);
    position: absolute;
  }
  @media (min-width: $small-breakpoint) {
    .pineapple__container--main & {
      transform: translateY(5%);
    }
  }
  @media (min-width: $medium-breakpoint) {
    .pineapple__container--main & {
      transform: translateY(10%);
    }
  }
  div {
    width: 100%;
    padding: 1rem 0;

    @media (min-width: $large-breakpoint) {
      padding: 1.5rem 0;
    }
  }
  div span {
    background: $yellow;
    width: 70%;
    display: block;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    padding: 0.8rem 0 1rem 0;
    &.margin__pineapple {
      margin-bottom: 2rem;
    }
    @media (min-width: $medium-breakpoint) {
      padding: 1.2rem 0 1.3rem 0;
      .pineapple__container--main & {
        padding: 1rem 0 1.2rem 0;
      }
    }
    .pineapple__container--main & {
      width: 80%;
    }
  }

  .pineapple__container--main & {
  }
}

.template__title {
  margin-bottom: 1rem;
}

.ani-con {
  position: relative;
  display: inline-block;
  top: 0px;
  width: 2.2rem;
  height: 0.85rem;
  margin-bottom: 3rem;
}

.ani {
  position: absolute;
  top: 0px;
  text-align: right;
}

.we span i {
  position: relative;
  z-index: 10000;
}

.you span i {
  position: relative;
  z-index: 10000;
}

.we span i {
  font-style: normal;
  animation: 4s linear 0s infinite normal blend;
}

.you span i {
  font-style: normal;
  animation: 4s linear -2s infinite normal blend;
}

@keyframes blend {
  0% {
    opacity: 0;
  }
  26% {
    opacity: 1;
  }
  51% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.pineapple__text--animated:before {
  content: "we";
  transition: none;
  animation: switch 4s linear infinite;
}

.pineapple__text--special {
  width: auto;
}

@keyframes switch {
  0% {
    content: "We ";
  }
  50% {
    content: "You ";
  }
  100% {
    content: "We ";
  }
}
//
//@keyframes switch {
//  0% {
//    visibility: visible;
//  }
//  100% {
//visibility: hidden;
//  }
//}

// We Gebana
.we-gebana__container {
  img {
    padding: 2rem;
  }
}

.storyteaser__container {
  //max-height: 480px;
  height: 100%;
  padding: 2rem 1.5rem 6rem 2rem;
  background-color: $yellow;
  span {
    color: $white;
    text-indent: 3rem;
    display: block;
    line-height: 1.1;
  }

  @media (max-width: $medium-breakpoint) {
    padding: 1rem 0.5rem 1rem 1rem;
    hyphens: auto;
    height: 320px;
  }
}

// Teaser Grid

.teaser__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: $small-breakpoint) {
    justify-content: space-around;
  }
  .alternating {
    display: block;
    width: 300px;
    height: auto;
    @media (max-width: $small-breakpoint) {
      width: 250px;
    }
  }
}

.small__teaser-container {
  margin-bottom: 24px;
}

.small__teaser {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease;
  border: none;
}

.teaser-image__svg {
  max-height: 220px;
}

.large-teaser-image__svg {
  max-height: 440px;
}

.small__teaser-title {
  @extend %serif-black-italic;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 100%;
}

.small__teaser-content {
  width: 100%;
  @media (min-width: $small-breakpoint) {
    width: 80%;
  }
}

;@import "sass-embedded-legacy-load-done:44";