#ccp {
  .ccp-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #e9e9e6;
    z-index: 2000;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
  }
  .ccp-banner,
  .ccp-panel {
    .outer {
      display: flex;
      max-width: 1128px;
      margin: 0 auto;
      width: 80%;
      @media screen and (max-width: 960px) {
        width: 100%;
        flex-direction: column;
      }
    }
    .inner {
      padding: 1rem;
      text-align: left;
    }
    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
    }
  }
  .ccp-revoke {
    .btn-revoke {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
    }
  }

  a {
    border-bottom: none;
  }
  p {
    margin-bottom: 0;
    line-height: 1.5;
    > a {
      border-bottom: 2px solid #ffce00;
      :hover {
        color: $black;
        opacity: 0.7;
      }
    }
  }
  .btn {
    font-size: 1.1875rem;
    font-family: "FFDINStdCondensedBlack";
    text-transform: uppercase;
    display: inline-block;
    background: #ffce00;
    padding: 0.8rem;
    margin: 0.8rem;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
      color: $black;
    }
  }

  .btn-panel,
  .btn-cancel {
    background: $white;
  }

  .ccp-panel {
    background: rgba(#000000, 0.66);
    position: fixed;
    min-height: 100vh;
    z-index: 2000;
    left: 0;
    top: 0;
    right: 0;
    overflow: scroll;
    font-size: 0.875rem;
    .outer {
      background: #e9e9e6;
      flex-direction: column;
      position: absolute;
      @media screen and (min-width: 600px) {
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }
      > * {
        width: 85%;
        margin: 1rem auto;
      }
    }
    form {
      label {
        position: relative;
      }
      .group {
        display: flex;
        margin-top: 1rem;
      }
    }
  }
  .title,
  .label-title {
    @extend h3;
  }

  input {
    visibility: hidden;
    min-width: 2.5rem;
  }
  label::before {
    content: " ";
    position: absolute;
    top: 4px;
    margin-left: -32px;
    background: #333;
    width: 20px;
    height: 20px;
  }

  input:checked + label::after {
    content: " ";
    position: absolute;
    top: 8px;
    margin-left: -28px;
    background: #ffce00;
    width: 12px;
    height: 12px;
  }

  // input:disabled + label::after {
  //   background: #e9e9e6;
  // }
}

;@import "sass-embedded-legacy-load-done:49";